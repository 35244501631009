import { ref, computed, watch } from 'vue';
import tinycolor from 'tinycolor2';

const THEMES = {
  default: {
    primary: '#293847',
    secondary: '#F7F4F1',
    highlight: '#03A9F4',
    success: '#9aeb3e',
    info: '#00a2ff',
    warning: '#FFB300',
    error: '#E53935'
  },
  vision: {
    primary: '#293847',
    secondary: '#f6f6f6',
    'secondary-1': '#FFFFFF', // => generally is not necessary to define every shade but if required those can be specified like so
    highlight: '#03A9F4'
  },
  mcc: {
    primary: '#03384C', // '#012530',
    secondary: '#012530', // '#00384C',
    // 'secondary-1': '#004b65',
    // 'secondary-2': '#005e7f',
    // 'secondary-3': '#007098',
    highlight: '#159DE7'
  },
  energy: {
    primary: '#3B3C50',
    secondary: '#565d6b',
    highlight: '#FAA044'
  },
  arneg: {
    primary: '#fefefe',
    secondary: '#ebebeb',
    highlight: '#e1001e'
  },
  'aqua-dark': {
    primary: '#454545',
    secondary: '#5E5E5E',
    highlight: '#94FFED'
  }
};

const COMMON = {
  '--chart-color-1': '#26a69a',
  '--chart-color-2': '#66bb6a',
  '--chart-color-3': '#9ccc65',
  '--chart-color-4': '#d4e157',
  '--chart-color-5': '#ffee58',
  '--chart-color-6': '#ffca28',
  '--chart-color-7': '#ffa726',
  '--chart-color-8': '#ff7043',
  '--chart-color-9': '#ef5350',
  '--chart-color-10': '#ec407a',
  '--chart-color-11': '#ab47bc',
  '--chart-color-12': '#7e57c2',
  '--chart-color-13': '#5c6bc0',
  '--chart-color-14': '#42a5f5',
  '--chart-color-15': '#29b6f6',
  '--chart-color-16': '#26c6da',
  '--chart-color-17': '#78909c',
  '--chart-color-18': '#bdbdbd',
  '--chart-color-19': '#8d6e63',
  '--chart-color-20': '#212121',

  '--std-form-color-main': 'var(--color-3, #03A9F4)',
  '--std-form-color-bg': 'var(--color-1, #F7F4F1)',
  '--std-form-color-text': 'var(--color-1-text, #1e272f)',
  '--std-form-color-alt': 'var(--color-1-alt, #cac7c5)',
  '--std-form-color-alt-2': 'var(--color-1-alt-2, #ececec)',

  /* BACKWARD COMPATIBILITY */
  '--color-ok': 'var(--color-success)',
  '--color-ok-text': 'var(--color-success-text)'
  /* BACKWARD COMPATIBILITY */
};

function getTextColor (color) {
  return tinycolor.mostReadable(color, ['#FAFAFA', '#3C3C3C', '#212121']).toHexString();
}

function hex (color) {
  return color ? tinycolor(color).toHexString() : undefined;
}

function getAltColors (color) {
  const brightness = (tinycolor(color).getBrightness() * 100) / 255;

  if (brightness <= 7) {
    return {
      1: tinycolor(color).lighten(5).toHexString(),
      2: tinycolor(color).lighten(10).toHexString(),
      3: tinycolor(color).lighten(15).toHexString()
    };
  } else if (brightness >= 93) {
    return {
      1: tinycolor(color).darken(5).toHexString(),
      2: tinycolor(color).darken(10).toHexString(),
      3: tinycolor(color).darken(15).toHexString()
    };
  } else if (brightness < 50) {
    return {
      1: tinycolor(color).darken(5).toHexString(),
      2: tinycolor(color).lighten(5).toHexString(),
      3: tinycolor(color).lighten(10).toHexString()
    };
  } else {
    return {
      1: tinycolor(color).lighten(5).toHexString(),
      2: tinycolor(color).darken(5).toHexString(),
      3: tinycolor(color).darken(10).toHexString()
    };
  }
}

function generateColors (theme) {
  theme = theme != null ? theme : {};

  for (const color in THEMES.default) {
    if (!theme[color]) { theme[color] = THEMES.default[color]; }
  }

  const primaryAlt = getAltColors(theme.primary);
  const secondaryAlt = getAltColors(theme.secondary);
  const highlightAlt = getAltColors(theme.highlight);

  const colors = {
    '--color-primary': hex(theme.primary),
    '--color-primary-text': hex(theme['primary-text']) || getTextColor(theme.primary),
    '--color-primary-1': hex(theme['primary-1']) || primaryAlt[1],
    '--color-primary-2': hex(theme['primary-2']) || primaryAlt[2],
    '--color-primary-3': hex(theme['primary-3']) || primaryAlt[3],

    '--color-secondary': hex(theme.secondary),
    '--color-secondary-text': hex(theme['secondary-text']) || getTextColor(theme.secondary),
    '--color-secondary-1': hex(theme['secondary-1']) || secondaryAlt[1],
    '--color-secondary-2': hex(theme['secondary-2']) || secondaryAlt[2],
    '--color-secondary-3': hex(theme['secondary-3']) || secondaryAlt[3],

    '--color-highlight': hex(theme.highlight),
    '--color-highlight-text': hex(theme['highlight-text']) || getTextColor(theme.highlight),
    '--color-highlight-1': hex(theme['highlight-1']) || highlightAlt[1],
    '--color-highlight-2': hex(theme['highlight-2']) || highlightAlt[2],
    '--color-highlight-3': hex(theme['highlight-3']) || highlightAlt[3],

    '--color-success': hex(theme.success),
    '--color-success-text': getTextColor(theme.success),

    '--color-info': hex(theme.info),
    '--color-info-text': getTextColor(theme.info),

    '--color-warning': hex(theme.warning),
    '--color-warning-text': getTextColor(theme.warning),

    '--color-error': hex(theme.error),
    '--color-error-text': getTextColor(theme.error)
  };

  for (const varName in COMMON) {
    colors[varName] = theme[varName] || COMMON[varName];
  }

  return colors;
}

function applyTheme (colors) {
  for (const color in colors) {
    document.body.style.setProperty(color, colors[color]);
  }
}

function applyCommon () {
  for (const varName in COMMON) {
    document.body.style.setProperty(varName, COMMON[varName]);
  }
}

export default {
  install: function (app, options) {
    const theme = ref('');
    const colors = ref({});

    if (options) {
      if (typeof options.default === 'object' || (typeof options.default === 'string' && THEMES[options.default])) {
        theme.value = options.default;
      }
    }

    if (theme.value === '') { theme.value = 'vision'; }

    watch(theme, () => {
      if (typeof theme.value === 'object' || (typeof theme.value === 'string' && THEMES[theme.value])) {
        colors.value = generateColors(typeof theme.value === 'object' ? theme.value : THEMES[theme.value]);
        applyTheme(colors.value);
      }
    });

    applyCommon();

    colors.value = generateColors(typeof theme.value === 'object' ? theme.value : THEMES[theme.value]);
    applyTheme(colors.value);

    app.provide('$theme', computed({
      get: () => theme.value,
      set: (newVal) => {
        theme.value = newVal;
      }
    }));
    app.provide('$colors', computed(() => colors.value));
  }
};
