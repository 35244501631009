<template>
  <div :class="[ 'CNSTachimeter', { 'loading': loading }]" ref="container" v-bind="$attrs"></div>
  <VI-Placeholder :loading="loading" :component="containerRef" :color="$colors['--color-highlight']"/>
</template>

<script>
/* global $, tachimeterObj */

import VIPlaceholder from './VI-Placeholder.vue';

export default {
  name: 'CNS-Tachimeter',
  inject: ['$colors'],
  components: {
    VIPlaceholder
  },
  props: {
    label: { type: String, default: '' },
    value: { type: [String, Number], default: 0 },
    valueDate: { type: Number, default: 0 },
    um: { type: String, default: '' },
    decDigits: { type: Number, default: 2 },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      tachimeter: undefined,
      containerRef: undefined
    };
  },
  watch: {
    value: function () {
      if (!this.tachimeter) { return; }
      this.tachimeter.setValue({ value1: this.value, date1: this.valueDate });
    },
    um: function () {
      if (!this.tachimeter) { return; }
      this.tachimeter.options.itemCfg.items[0].unit = this.um;
      this.tachimeter.drawCanvas();
    },
    min: function () {
      if (!this.tachimeter) { return; }
      this.tachimeter.options.itemCfg.primaryStartScale = this.min;
      this.tachimeter.draw();
    },
    max: function () {
      if (!this.tachimeter) { return; }
      this.tachimeter.options.itemCfg.primaryEndScale = this.max;
      this.tachimeter.draw();
    }
  },
  mounted () {
    this.$nextTick(() => {
      // eslint-disable-next-line new-cap
      this.tachimeter = new tachimeterObj({
        appendTo: $(this.$refs.container),
        itemCfg: {
          label: this.label,
          fontFamily: 'Arial', // "Montserrat", // Montserrat doesn't work
          fontWeight: '300',
          primaryColor: this.$colors['--color-highlight'],
          fontColor: this.$colors['--color-primary-text'], // "rgb(250,250,250)",
          backColor: this.$colors['--color-primary-3'], // "rgb( 41, 56, 71)",
          primaryStartScale: this.min,
          primaryEndScale: this.max,
          items: [
            { id: 0, gain: 1, offset: 0, unit: this.um, nDec: this.decDigits, tipo: 'num' }
          ],
          css: { width: '100%', height: '100%', 'min-width': '100px', display: 'inline-block' },
          time: true
        }
      });

      this.tachimeter.setValue({ value1: this.value, date1: this.valueDate });

      this.containerRef = this.$refs.container;
    });
  }
};
</script>

<style scoped>
.CNSTachimeter{
}

.CNSTachimeter.loading{
  opacity: 0;
}
</style>
