<template>
  <div :class="[ 'VITableHead', { 'searchable': searchable, 'search': searchActive } ]" ref="cell" v-on:click="cellClicked">
    <div class="headContent">
      <VI-Icon :class="[ 'headOrderIcon', { 'active': order != 0 } ]" v-if="orderable" :type="order < 0 ? 'sort-up' : order > 0 ? 'sort-down' : 'sort'" v-on:click.stop.prevent="toggleOrder"/>
      <div class="headTitle" v-if="!searchActive" >{{value}}</div>
      <VI-Input class="headSearchBar" ref="searchBar" v-if="searchActive" value="" v-on:update="searchUpdate" :placeholder="value" />
      <VI-Icon class="headSearchIcon" v-if="!searchActive && searchable" v-on:click.stop.prevent="openSearch" type="search"/>
      <VI-Icon class="headCloseSearchIcon" v-if="searchActive"  type="times" v-on:click.stop.prevent="closeSearch"/>
    </div>
  </div>
</template>

<script>
import VIIcon from './VI-Icon.vue';
import VIInput from './VI-Input.vue';

export default {
  name: 'VI-Table-head',
  components: {
    VIIcon,
    VIInput
  },
  props: {
    value: { type: [String, Number], default: '' },
    searchable: { type: Boolean, default: false },
    orderable: { type: Boolean, default: false },
    order: { type: Number, default: 0 }
  },
  data () {
    return {
      searchActive: false
    };
  },
  methods: {
    getLeft () {
      return this.$refs.cell.getBoundingClientRect().left;
    },
    getWidth () {
      return this.$refs.cell.offsetWidth;
    },
    cellClicked () {
      if (this.searchActive) { return; }
      // if( this.searchable ){
      //     this.searchActive = true;
      //     this.$nextTick(() => { this.$refs.searchBar.focus() })
      // }else{
      this.$emit('cell-click');
      // }
    },
    searchUpdate (searchStr) {
      this.$emit('column-search', searchStr);
    },
    openSearch () {
      this.searchActive = true;
      this.$nextTick(() => { this.$refs.searchBar.focus(); });
    },
    closeSearch () {
      this.searchActive = false;
      this.$emit('column-search', '');
    },
    toggleOrder () {
      this.$emit('column-order', this.order + 1 === 2 ? -1 : this.order + 1);
    }
  }
};
</script>

<style scoped>
.VITableHead{
  height: 42px;
  text-align: center;
  border: none;
}

.VITableHead:not(:last-child){
  box-shadow: inset -1px 0 var(--color-primary-1);
}

.VITableHead.searchable{
  min-width: 150px;
}

.VITableHead > .headContent{
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 5px;
}

.VITableHead > .headContent > .headOrderIcon{
  font-size: 1.8em;
  flex: 0 0 auto;
  width: 14px;
  cursor: pointer;
  opacity: .3;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.VITableHead > .headContent > .headOrderIcon.active{
  color: var(--color-highlight);
  opacity: 1;
}

.VITableHead > .headContent > .headTitle{
  font-size: 1.2em;
  padding: 4px 8px;
  flex: 0 1 100%;
}

.VITableHead > .headContent > .headSearchIcon{
  flex: 0 0 auto;
  font-size: 1.3em;
  cursor: pointer;
}

.VITableHead > .headContent > .headSearchIcon:hover{
  color: var(--color-highlight);
}

.VITableHead > .headContent > .headSearchBar{
  font-size: 1.2em;
  padding: 4px;
  flex: 0 1 100%;
  width: auto;
  min-width: 0;

  /* --bg-color: var(--color-primary-2); */
  /* --text-color: var(--color-primary-text); */
}

.VITableHead > .headContent > .headSearchBar > *{
  /* background: var(--color-primary-text); */
  /* color: var(--color-primary-2); */
}

.VITableHead > .headContent > .headCloseSearchIcon{
  flex: 0 0 auto;
  font-size: 1.5em;
  cursor: pointer;
}

.VITableHead > .headContent > .headCloseSearchIcon:hover{
  color: var(--color-error);
}

</style>
