<template>
  <div :class="[ 'VICheckbox', { 'checked': checked, 'unchecked': !checked, 'readonly': readonly } ]" @click.stop.prevent="toggle">
    <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path class="check" d="M15 50 L40 75 L85 25" fill="none" stroke="currentColor" stroke-width="15" stroke-linecap="round"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'VI-Checkbox',
  props: {
    modelValue: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      checked: this.modelValue
    };
  },
  watch: {
    modelValue: function () {
      this.checked = this.modelValue;
    }
  },
  methods: {
    toggle () {
      if (this.readonly) { return; }
      this.checked = !this.checked;
      this.$emit('update:modelValue', this.checked);
    }
  }
};
</script>

<style scoped>
.VICheckbox{
  width: 22px;
  height: 22px;
  background-color: var(--color-secondary);
  border-radius: 2px;
  cursor: pointer;
  transition: color .2s ease, background-color .2s ease, box-shadow .2s ease;
  position: relative;
}

.VICheckbox:hover{
  background-color: var(--color-secondary-1);
}

.VICheckbox.checked{
  color: var(--color-highlight-text);
  background-color: var(--color-highlight);
}

.VICheckbox.checked:hover{
  background-color: var(--color-highlight-1);
}

.VICheckbox.readonly{
  cursor: initial;
}

.VICheckbox.readonly.checked{
  color: var(--color-highlight-text);
  background-color: var(--color-highlight);
  opacity: .6;
}

.VICheckbox > svg{
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
}

.VICheckbox > svg > .check{
  stroke-dasharray: 110;
  stroke-dashoffset: 110;
}

.VICheckbox.checked > svg > .check{
  animation: draw .3s ease .1s forwards;
}

@keyframes draw {
  to { stroke-dashoffset: 0; }
}
</style>
