<template>
  <div class="VIInput">
    <div class="label" v-if="label != ''" v-on:click="focus" >{{label}}</div>
    <input class="input" ref="input" :type="type" :readonly="readonly" :value="value" v-on:change="change" v-on:keyup="keyup" :placeholder="placeholder" size="1"/>
  </div>
</template>

<script>
export default {
  name: 'VI-Input',
  props: {
    value: { type: [String, Number], default: '' },
    label: { type: [String, Number], default: '' },
    placeholder: { type: [String, Number], default: '' },
    readonly: { type: Boolean, default: false },
    type: { type: String, validator: (val) => { return ['text', 'number', 'password', 'color'].indexOf(val) > -1; }, default: 'text' }
  },
  methods: {
    change (e) {
      this.$emit('update:value', this.type === 'number' ? Number(this.$refs.input.value) : String(this.$refs.input.value)); // this is only to work with v-model:value
      this.$emit('update', this.type === 'number' ? Number(this.$refs.input.value) : String(this.$refs.input.value));
      e.stopPropagation();
    },
    keyup (e) {
      this.$emit('keyup', this.type === 'number' ? Number(this.$refs.input.value) : String(this.$refs.input.value));
      e.stopPropagation();
    },
    focus () {
      this.$refs.input.focus();
    }
  }
};
</script>

<style scoped>
.VIInput{
  display: flex;
  flex-flow: column nowrap;
  width: 250px;
  padding: 5px 2px;

  --bg-color: var(--color-secondary-1);
  --accent-color: var(--color-highlight);
  --text-color: var(--color-secondary-text);
}

.VIInput > .label{
  text-align: left;
  padding: 0 10px 5px 8px;
  cursor: pointer;
  color: var(--accent-color);
}

.VIInput > .input{
  border-radius: 16px;
  height: 31px;
  width: 100%;
  outline: none;
  padding: 0px 9px;
  font-size: 1em;
  font-weight: 600;

  border: 2px solid var(--bg-color);
  background-color: var(--bg-color);
  color: var(--text-color);
}

.VIInput > .input:focus{
  border-color: var(--accent-color);
}
</style>
