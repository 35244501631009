<template>
  <div class="VIWindy" ref="container">
    <iframe v-if="initialized && src" :width="width" :height="height" :src="src" frameborder="0"></iframe>
    <div v-else class="placeholder" :style="{ 'width': width + 'px', 'height': height + 'px' }" >
      <div><img src="https://www.windy.com/img/logo201802/logo-text-windycom-white.svg" @error="noImg = true;"/></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VI-Windy',
  props: {
    lat: { type: String, default: '45.464149' },
    lon: { type: String, default: '9.191647' },
    metricWind: { type: String, default: 'default' }, // kt, m/s, km/h, mph, bft
    metricTemp: { type: String, default: 'default' }, // °C, °F
    overlay: { type: String, default: 'wind' }, // Basic wind: 'wind', Basic clouds: 'clouds', Wind gusts: 'gust', Wind accumulation: 'gustAccu'
    level: { type: String, default: 'surface' }, // 'surface' or can be espressed in m, ft and h (hPa),
    marker: { type: String, default: 'true' },
    zoom: { type: Number, default: 9 },
    forecast: { type: Boolean, default: false },
    debounce: { type: Number, default: 0 }, // if > 0 will debounce the url change on the component
    reflowDebounce: { type: Number, default: 500 } // if > 0 will debounce the reflow on window resize
  },
  data () {
    return {
      initialized: false,
      width: 0,
      height: 0,
      calendar: 'now', // now, 12, 24 => now or next 12 or 24 hours
      // detail: '', // true => this should be the trigger for the forecast overlay
      message: 'true', // true => hide promotional text
      pressure: '', // true => enable pressure iso lines
      menu: '', // true => deactivates all windy overlays

      src: '',
      srcTo: undefined,
      reflowTo: undefined,
      iframeComp: undefined,
      noImg: false
    };
  },
  computed: {
    detail: function () { return this.forecast ? 'true' : ''; },
    src_: function () {
      if (!this.initialized) { return ''; }

      return encodeURI((`https://embed.windy.com/embed2.html?
                      lat=${this.lat}&
                      lon=${this.lon}&
                      detailLat=${this.lat}&
                      detailLon=${this.lon}&
                      width=${this.width}&
                      height=${this.height}&
                      zoom=${this.zoom}&
                      level=${this.level}&
                      overlay=${this.overlay}&
                      product=ecmwf&
                      menu=${this.menu}&
                      message=${this.message}&
                      marker=${this.marker}&
                      calendar=${this.calendar}&
                      pressure=${this.pressure}&
                      type=map&
                      location=coordinates&
                      detail=${this.detail}&
                      metricWind=${this.metricWind}&
                      metricTemp=${this.metricTemp}&
                      radarRange=-1`).replace(/ /g, '').replace(/\n/g, ''));
    }
  },
  watch: {
    src_: function () {
      clearTimeout(this.srcTo);
      this.srcTo = setTimeout(() => {
        this.src = this.src_;
      }, this.debounce);
    }
  },
  methods: {
    reflow (immediate) {
      clearTimeout(this.reflowTo);
      this.reflowTo = setTimeout(() => {
        if (!this.$refs.container) { return; }
        const containerSize = this.$refs.container.getBoundingClientRect();

        this.width = containerSize.width;
        this.height = containerSize.height;
      }, immediate ? 0 : this.reflowDebounce);
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.reflow(true);
      window.addEventListener('resize', () => { this.reflow(); });

      this.initialized = true;
    });
  }
};
</script>

<style scoped>
.VIWindy{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.placeholder{
  flex: 0 0 auto;
  background: currentColor;
  border-radius: 5px;
  animation: pulseOpacity 3s ease-in-out infinite;
  opacity: .6;
}

@keyframes pulseOpacity {
  50%{
    opacity: .3;
  }
}

/* Windy specifc style */
.placeholder{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
</style>
