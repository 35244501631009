<template>
  <div :class="[ 'CNSWindDirection', { 'loading': loading } ]" ref="container" v-bind="$attrs"></div>
  <VI-Placeholder :loading="loading" :component="containerRef" :color="$colors['--color-highlight']"/>
</template>

<script>
/* global windDirectionObj */
import VIPlaceholder from './VI-Placeholder.vue';

export default {
  name: 'CNS-Wind-Direction',
  inject: ['$colors'],
  props: {
    value: { type: Number, default: NaN, required: true },
    valueDate: { type: Number, default: 0 },
    colorConfig: { type: Object, default: function () { return {}; } },
    loading: { type: Boolean, default: false }
  },
  components: {
    VIPlaceholder
  },
  data () {
    return {
      rosa: undefined,
      containerRef: undefined,
      itemCfg: {
        date: true,
        lineColor: this.$colors['--color-primary-text'],
        backColor: '#FFF0',
        roseColor: this.$colors['--color-primary-2'],
        arrowColor: this.$colors['--color-highlight'],
        degreeColor: this.$colors['--color-highlight'],
        circleColor: this.$colors['--color-primary-3'],
        cardinalColor: this.$colors['--color-primary-text'],
        abilBorder: true,
        css: {
          width: 300
        },
        items: []
      }
    };
  },
  watch: {
    value: function () {
      if (!this.rosa) return;
      this.rosa.setValue(this.value, this.valueDate);
    },
    colorConfig: function () {
      this.createWidget();
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.createWidget();
    });
  },
  methods: {
    createWidget () {
      Object.assign(this.itemCfg, this.colorConfig);

      const windObjConfig = {
        appendTo: (this.$refs.container),
        itemCfg: this.itemCfg,
        WHFactor: 1,
        draw: true
      };

      if (this.rosa) this.rosa.$div.remove();
      /* eslint-disable-next-line new-cap */
      this.rosa = new windDirectionObj(windObjConfig);

      this.rosa.setValue(this.value, this.valueDate);

      this.containerRef = this.$refs.container;
    }
  }
};
</script>

<style scoped>
.CNSWindDirection {
}
</style>
