// initial state
const state = () => ({
  configs: {}
});

// getters
const getters = {};

// mutations
const mutations = {
  SET_CONFIG (state, { configId, config }) {
    state.configs[configId] = config;
  }
};

// actions
const actions = {};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
};
