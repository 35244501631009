<template>
  <div :class="[ 'VIDiv', { 'noVScroll': noVScroll, 'noHScroll': noHScroll }]" ref="div">
    <div :class="[ 'vScrollbar', { 'show': vScrollbarShow, 'hoverShow': vScrollbarShowOnHover, 'drag': vScrollbarDrag } ]" ref="vSscrollbar" :style="vScrollbarStyle" @mousedown.prevent.stop="vScrollbarMousedown" @click.stop></div>
    <div :class="[ 'hScrollbar', { 'show': hScrollbarShow, 'hoverShow': hScrollbarShowOnHover, 'drag': hScrollbarDrag } ]" ref="hSscrollbar" :style="hScrollbarStyle" @mousedown.prevent.stop="hScrollbarMousedown" @click.stop></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VI-Div',
  props: {
    noVScroll: { type: Boolean, default: false },
    noHScroll: { type: Boolean, default: false },
    doNotHideVScroll: { type: Boolean, default: false },
    doNotHideHScroll: { type: Boolean, default: false }
  },
  data () {
    return {
      vScrollbarShow: false,
      vScrollbarStyle: { top: 0, height: 0 },
      vScrollHideTO: undefined,
      vScrollbarShowOnHover: false,
      vScrollbarDrag: false,
      vScrollbarDragStart: 0,
      vScrollbarDragStartScroll: 0,

      hScrollbarShow: false,
      hScrollbarStyle: { left: 0, width: 0 },
      hScrollHideTO: undefined,
      hScrollbarShowOnHover: false,
      hScrollbarDrag: false,
      hScrollbarDragStart: 0,
      hScrollbarDragStartScroll: 0,

      hideTimeout: 1000
    };
  },
  methods: {
    vScrollbarShowIfNeeded () {
      if (this.noVScroll || !this.$refs.div) { return; }
      if (this.$refs.div.scrollHeight > this.$refs.div.offsetHeight) {
        !this.vScrollbarShow && (this.vScrollbarShow = true);
        !this.vScrollbarShowOnHover && (this.vScrollbarShowOnHover = true);
      } else {
        this.vScrollbarShowOnHover && (this.vScrollbarShowOnHover = false);
      }
    },
    hScrollbarShowIfNeeded () {
      if (this.noHScroll || !this.$refs.div) { return; }
      if (this.$refs.div.scrollWidth > this.$refs.div.offsetWidth) {
        !this.hScrollbarShow && (this.hScrollbarShow = true);
        !this.hScrollbarShowOnHover && (this.hScrollbarShowOnHover = true);
      } else {
        this.hScrollbarShowOnHover && (this.hScrollbarShowOnHover = false);
      }
    },
    vScrollbarScheduleHide () {
      if (this.doNotHideVScroll) { return; }
      clearTimeout(this.vScrollHideTO);
      this.vScrollHideTO = setTimeout(() => {
        this.vScrollbarShow && (this.vScrollbarShow = false);
      }, this.hideTimeout);
    },
    hScrollbarScheduleHide () {
      if (this.doNotHideHScroll) { return; }
      clearTimeout(this.hScrollHideTO);
      this.hScrollHideTO = setTimeout(() => {
        this.hScrollbarShow && (this.hScrollbarShow = false);
      }, this.hideTimeout);
    },
    vScrollbarCalcPos () {
      if (this.noVScroll || !this.vScrollbarShow || !this.$refs.div) { return; }
      const scrollMaxRange = this.$refs.div.offsetHeight - (this.hScrollbarShow ? 14 : 6);
      const hiddenHeight = this.$refs.div.scrollHeight - this.$refs.div.offsetHeight;
      const heightsRel = this.$refs.div.offsetHeight / this.$refs.div.scrollHeight;
      const maxOffset = scrollMaxRange * (1 - heightsRel);

      this.vScrollbarStyle = {
        top: this.$refs.div.scrollTop + ((this.$refs.div.scrollTop / hiddenHeight) * maxOffset) + 'px',
        height: (scrollMaxRange * heightsRel) + 'px',
        right: (3 - this.$refs.div.scrollLeft) + 'px'
      };
    },
    hScrollbarCalcPos () {
      if (this.noHScroll || !this.hScrollbarShow || !this.$refs.div) { return; }
      const scrollMaxRange = this.$refs.div.offsetWidth - (this.vScrollbarShow ? 14 : 6);
      const hiddenWidth = this.$refs.div.scrollWidth - this.$refs.div.offsetWidth;
      const widthsRel = this.$refs.div.offsetWidth / this.$refs.div.scrollWidth;
      const maxOffset = scrollMaxRange * (1 - widthsRel);

      this.hScrollbarStyle = {
        left: this.$refs.div.scrollLeft + ((this.$refs.div.scrollLeft / hiddenWidth) * maxOffset) + 'px',
        width: (scrollMaxRange * widthsRel) + 'px',
        bottom: (3 - this.$refs.div.scrollTop) + 'px'
      };
    },
    vScrollbarMousedown (e) {
      this.vScrollbarDrag = true;
      this.vScrollbarDragStart = e.clientY;
      this.vScrollbarDragStartScroll = this.$refs.div.scrollTop;
      window.addEventListener('mousemove', this.vScrollbarMousemove);
    },
    hScrollbarMousedown (e) {
      this.hScrollbarDrag = true;
      this.hScrollbarDragStart = e.clientX;
      this.hScrollbarDragStartScroll = this.$refs.div.scrollLeft;
      window.addEventListener('mousemove', this.hScrollbarMousemove);
    },
    vScrollbarMousemove (e) {
      const scrollMaxRange = this.$refs.div.offsetHeight - (this.hScrollbarShow ? 14 : 6);
      const hiddenHeight = this.$refs.div.scrollHeight - this.$refs.div.offsetHeight;
      const heightsRel = this.$refs.div.offsetHeight / this.$refs.div.scrollHeight;
      const maxOffset = scrollMaxRange * (1 - heightsRel);
      const mouseOffset = this.vScrollbarDragStart - e.clientY;

      // ( ( 100 * mouseOffset ) / maxOffset) = (( 100 * this.vScrollbarDragStartScroll - this.$refs.div.scrollTop ) / hiddenHeight)

      this.$refs.div.scrollTop = this.vScrollbarDragStartScroll - ((mouseOffset * hiddenHeight) / maxOffset);
    },
    hScrollbarMousemove (e) {
      const scrollMaxRange = this.$refs.div.offsetWidth - (this.vScrollbarShow ? 14 : 6);
      const hiddenWidth = this.$refs.div.scrollWidth - this.$refs.div.offsetWidth;
      const widthsRel = this.$refs.div.offsetWidth / this.$refs.div.scrollWidth;
      const maxOffset = scrollMaxRange * (1 - widthsRel);
      const mouseOffset = this.hScrollbarDragStart - e.clientX;

      this.$refs.div.scrollLeft = this.hScrollbarDragStartScroll - ((mouseOffset * hiddenWidth) / maxOffset);
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.div.addEventListener('mouseenter', () => {
        this.vScrollbarShowIfNeeded();
        this.hScrollbarShowIfNeeded();

        this.vScrollbarCalcPos();
        this.hScrollbarCalcPos();

        this.vScrollbarScheduleHide();
        this.hScrollbarScheduleHide();
      });

      this.$refs.div.addEventListener('mouseleave', () => {
        this.vScrollbarScheduleHide();
        this.hScrollbarScheduleHide();
      });

      this.$refs.div.addEventListener('scroll', () => {
        this.vScrollbarShowIfNeeded();
        this.hScrollbarShowIfNeeded();

        this.vScrollbarCalcPos();
        this.hScrollbarCalcPos();

        this.vScrollbarScheduleHide();
        this.hScrollbarScheduleHide();
      });

      window.addEventListener('mouseup', () => {
        if (this.vScrollbarDrag) {
          this.vScrollbarDrag = false;
          window.removeEventListener('mousemove', this.vScrollbarMousemove);
        }

        if (this.hScrollbarDrag) {
          this.hScrollbarDrag = false;
          window.removeEventListener('mousemove', this.hScrollbarMousemove);
        }
      });

      this.vScrollbarShowIfNeeded();
      this.hScrollbarShowIfNeeded();

      this.vScrollbarCalcPos();
      this.hScrollbarCalcPos();

      this.vScrollbarScheduleHide();
      this.hScrollbarScheduleHide();
    });
  }
};
</script>

<style scoped>
.VIDiv{
  --scrollbar-color: var(--color-secondary);

  position: relative;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.VIDiv::-webkit-scrollbar {
display: none;
}

.VIDiv.noVScroll{ overflow-y: hidden; }
.VIDiv.noHScroll{ overflow-x: hidden; }

.vScrollbar, .hScrollbar{
  position: absolute;
  border-radius: 10px;
  background: var(--scrollbar-color);
  z-index: 100;
  opacity: 0;
  transition: opacity .3s ease, height .3s ease, width .3s ease;
  cursor: pointer;
}

.vScrollbar{
  right: 3px;
  width: 6px;
  max-height: calc(100% - 6px);
  transform: translateY( 3px );
}

.hScrollbar{
  bottom: 3px;
  height: 6px;
  max-width: calc(100% - 6px);
  transform: translateX( 3px );
}

.vScrollbar.show, .hScrollbar.show{
  opacity: .2;
}

.vScrollbar.hoverShow:hover, .vScrollbar.hoverShow.drag{
  opacity: .4;
  width: 15px;
}

.hScrollbar.hoverShow:hover, .hScrollbar.hoverShow.drag{
  opacity: .4;
  height: 15px;
}
</style>
