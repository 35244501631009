<template>
  <div id="external">
    <iframe class="pageFrame" :src="src" frameborder="0"/>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      src: this.$route.params.url
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        if (this.src !== this.$route.params.url) {
          this.src = this.$route.params.url || '#';
        }
      }
    }
  }
};
</script>

<style>
#external{
  display: flex;
  flex-flow: row nowrap;
}

.pageFrame{
  flex: 0 1 100%;
}
</style>
