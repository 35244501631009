<template>
  <div :class="[ 'VISelect', { 'readonly': readonly }]" ref="VISelect">
    <div class="label" v-if="label != ''" @click="openOptions">{{label}}</div>
    <div :class="['inputContainer', { 'expanded': showOptions, 'up': openUp, 'down': !openUp }]" ref="inputContainer" @click="openOptions">
      <div class="valueLabel" v-show="!showOptions">{{curValueLabel}}</div>
      <input class="filterInput" type="text" ref="filterInput" v-show="showOptions" :placeholder="curValueLabel" v-model="curFilter" @keydown.esc="showOptions = false" size="1"/>
      <VI-Icon class="optionsToggle" type="angle-down" @click="optionsToggleClick"/>
    </div>

    <teleport to="body">
      <transition name="fade">
        <div :class="['optionsList', { 'expanded': showOptions, 'up': openUp, 'down': !openUp }]" ref="optionsList" v-if="showOptions" :style="optionsListStyle" @wheel.stop>
          <VI-Scroll-Div noHScroll doNotHideVScroll class="optionsScroller" >
            <template v-for="opt in $_options" :key="opt.val">
              <div v-if="opt.title" class="optionTitle">{{opt.lbl}}</div>
              <div v-else class="option" @click="selected(opt.val)">{{opt.lbl}}</div>
            </template>
            <template v-if="$_options.length == 0">
              <div class="noOptions">{{edw.nothingFound}}</div>
            </template>
          </VI-Scroll-Div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
/* global edw */
import VIIcon from './VI-Icon.vue';
import VIScrollDiv from './VI-Scroll-Div.vue';

export default {
  name: 'VI-Select',
  components: {
    VIIcon,
    VIScrollDiv
  },
  props: {
    value: { type: [String, Number], default: '' },
    label: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    options: { type: Array, default: () => { return []; } }
  },
  data: function () {
    return {
      curValue: this.value,
      curFilter: '',
      showOptions: false,
      openUp: false,
      optionsListStyle: {},
      edw: {
        nothingFound: edw('nothingFound')
      }
    };
  },
  computed: {
    $_options: function () {
      if (this.showOptions && this.curFilter !== '') {
        return this.options.filter((el) => { return (String(el.lbl).toLowerCase().indexOf(this.curFilter.toLowerCase()) > -1); });
      } else {
        return this.options;
      }
    },
    curValueLabel: function () {
      const selOption = this.options.find((el) => { return String(el.val) === String(this.curValue); });
      return selOption ? selOption.lbl : '';
    }
  },
  watch: {
    showOptions: function (newVal) {
      if (newVal) {
        const inputFixPos = this.$refs.inputContainer.getBoundingClientRect();
        const optionsListHeight = Math.min(this.options.length * 22 + 2, 300) + 2;

        if ((inputFixPos.top + inputFixPos.height + optionsListHeight) > document.body.offsetHeight) {
          this.openUp = true;
          this.optionsListStyle = {
            bottom: (document.body.offsetHeight - inputFixPos.top) + 'px',
            left: inputFixPos.left + 'px',
            width: this.$refs.inputContainer.offsetWidth + 'px'
          };
        } else {
          this.openUp = false;
          this.optionsListStyle = {
            top: (inputFixPos.top + this.$refs.inputContainer.offsetHeight) + 'px',
            left: inputFixPos.left + 'px',
            width: this.$refs.inputContainer.offsetWidth + 'px'
          };
        }

        document.body.addEventListener('click', this.bodyClick);
        document.body.addEventListener('wheel', this.bodyClick);

        this.$nextTick(() => { this.$refs.filterInput.focus(); }); // after the next draw
      } else {
        this.curFilter = '';
        document.body.removeEventListener('click', this.bodyClick);
        document.body.removeEventListener('wheel', this.bodyClick);
      }
    },
    value: function () {
      this.curValue = this.value;
    }
  },
  mounted () { },
  methods: {
    selected (selVal) {
      this.curValue = selVal;
      this.showOptions = false;
      this.$emit('update:value', this.curValue);
      this.$emit('update', this.curValue);
    },
    openOptions () {
      if (this.readonly) { return; }

      this.showOptions = true;
    },
    bodyClick (e) {
      if (isDescendant(this.$refs.VISelect, e.target)) { return; }

      this.showOptions = false;
    },
    optionsToggleClick (e) {
      if (this.showOptions) {
        this.showOptions = false;
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }
};

function isDescendant (parent, child) {
  let node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}
</script>

<style scoped>
.VISelect{
  display: flex;
  flex-flow: column nowrap;
  width: 250px;
  padding: 5px 2px;
}

.VISelect > .label{
  text-align: left;
  padding: 0 10px 5px 8px;
  cursor: pointer;
  color: var(--color-highlight);
}

.VISelect > .inputContainer{
  position: relative;
  border-radius: 16px;
  height: 31px;
  width: 100%;
  border: 2px solid var(--color-secondary-1);
  background-color: var(--color-secondary-1);
  color: var(--color-secondary-text);
  padding: 0px 9px;
  display: flex;
  align-items: center;
  cursor: pointer;

  transition: border-color  .2s ease,
              border-radius .2s ease;
}

.VISelect.readonly > .label{
  cursor: default;
}

.VISelect.readonly > .inputContainer{
  cursor: default;
}

.VISelect > .inputContainer.expanded.up{
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  border-bottom-color: var(--color-highlight);
  border-left-color: var(--color-highlight);
  border-right-color: var(--color-highlight);
  border-top: 0px solid var(--color-secondary-1);
  padding-top: 2px;
}

.VISelect > .inputContainer.expanded.down{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  border-top-color: var(--color-highlight);
  border-left-color: var(--color-highlight);
  border-right-color: var(--color-highlight);
  border-bottom: 0px solid var(--color-secondary-1);
  padding-bottom: 2px;
}

.VISelect > .inputContainer > .valueLabel{
  padding: 0px 2px;
  width: 100%;
  text-align: left;
}

.VISelect > .inputContainer > .filterInput{
  width: 100%;
  height: 100%;
  border: none;
  background-color: var(--color-secondary-1);
  color: var(--color-secondary-text);
  outline: none;
  font-size: 1em;
  font-weight: 600;
  height: 23px;
}

.VISelect > .inputContainer > .optionsToggle{
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 2em;
  width: 27px;
  height: 27px;
  line-height: 29px; /* logically this should be 27px but it looks mildly better with 29px */
  text-align: center;

  transition: transform .3s ease;
}

.VISelect.readonly > .inputContainer > .optionsToggle{
  opacity: .5;
}

.VISelect > .inputContainer.expanded > .optionsToggle{
  transform: rotate(180deg);
}

.optionsList{
  position: fixed;
  z-index: 99;
  border-left: 2px solid var(--color-highlight);
  border-right: 2px solid var(--color-highlight);
  background-color: var(--color-secondary-1);
  color: var(--color-secondary-text);
  /* overflow-x: hidden;
  overflow-y: auto; */
  max-height: 300px;

  transform: translateY(0);
}

.optionsList.up{
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-top: 2px solid var(--color-highlight);
}

.optionsList.down{
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-bottom: 2px solid var(--color-highlight);
}

.optionsList .optionsScroller{
  max-height: 300px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .optionsList::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .optionsList {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.fade-enter-active,
.fade-leave-active {
  transition: transform     .2s ease,
              border-color  .2s ease,
              border-radius .2s ease,
              opacity       .2s ease;
}

.fade-enter-from,
.fade-leave-to {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  transform: translateY(-15px);
  border-color: var(--color-secondary-1);
  opacity: 0;
}

.fade-enter-from.up,
.fade-leave-to.up {
  transform: translateY(10px);
}

.fade-enter-from.down,
.fade-leave-to.down {
  transform: translateY(-10px);
}

.optionsList .optionTitle {
  text-align: left;
  padding: 4px 11px;
  cursor: default;
  color: grey;
}

.optionsList .option{
  text-align: left;
  padding: 4px 11px;
  cursor: default;
}

.optionsList.down .option:hover:last-child {
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

.optionsList .option:hover{
  background: var(--color-highlight);
  color: var(--color-highlight-text);
}

.optionsList .option:last-child{
  padding-bottom: 6px;
}

.optionsList .noOptions{
  text-align: center;
  color: var(--color-secondary-text);
  opacity: .5;
  padding: 3px;
  font-size: .7em;
  cursor: default;
}
</style>
