<template>
  <div class="VILegend" :style="borderStyle">
    <div v-if="title != ''" class="title">
      <VI-Label :text="title"/>
    </div>
    <div class="legend">
      <template v-for="(element, index) in elements" :key="index">
        <div class="singleElement">
          <div v-if="element.point" class="point" :style="{ background: element.color }"/>
          <VI-Label class="text" :text="element.text"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import VILabel from './VI-Label.vue';

export default {
  name: 'VI-Legend',
  components: {
    VILabel
  },
  props: {
    title: { type: String, default: '' },
    elements: { type: Array, default: function () { return []; } },
    border: { type: Object, default: undefined }
  },
  computed: {
    borderStyle: function () {
      const style = {};
      if (this.border) {
        style.border = '1px solid';
        style['border-radius'] = '5px';
        if (typeof this.border === 'object' && this.border.color) {
          style.border = style.border + ' ' + String(this.border.color);
        }
      }
      return style;
    }
  }
};
</script>

<style scoped>
.VILegend {
  position: relative;
  bottom: 0;
  display: flex;
  flex: 0 0 auto;
  width: 230px;
  flex-flow: column;
  background: var(--color-primary-1);
  margin: 0 10px 10px 10px;
  padding: 5px 0;
}

.VILegend > .title {
  width: 100%;
  height: 24px;
  font-size: 1.4em;
  color: var(--color-primary-text);
  text-transform: uppercase;
}

.VILegend > .legend {
  display: flex;
  flex-flow: row wrap;
}

.VILegend > .legend > .singleElement {
  display: flex;
  flex-flow: row wrap;
  min-width: 50%;
  padding: 2px 15px;
  align-items: flex-start;
}

.VILegend > .legend > .singleElement > .point {
  border-radius: 10%;
  content: "";
  position: relative;
  height: 12px;
  width: 12px;
}

.VILegend > .legend > .singleElement > .text {
  text-align: start;
  font-size: 1.2em;
  padding: 0px 5px;
  flex: 1 1 0;
}
</style>
