import Nodes from '../lib/nodesUtils.js';

/* global HigJS */

// initial state
const state = () => ({
  loading: false,

  nodes: {},
  parameters: {},
  childNodesLoaded: {},
  fullNodesLoaded: {},
  plantInfoLoaded: {},
  allNodesLoaded: false,

  types: {},
  varPeriods: [],
  varTypes: []
});

// getters
const getters = {};

let loadingCalls = 0;
// mutations
const mutations = {
  START_LOAD (state) {
    if (++loadingCalls === 1) { state.loading = true; }
  },
  STOP_LOAD (state) {
    if (--loadingCalls === 0) { state.loading = false; }
  },

  SET_NODE_TYPES (state, types) {
    state.types = types;
  },

  SET_VAR_PERIODS (state, varPeriods) {
    state.varPeriods = varPeriods;
  },

  SET_VAR_TYPES (state, varTypes) {
    state.varTypes = varTypes;
  },

  UPDATE_NODES (state, nodes) {
    const updatedNodes = {};
    for (const nodeId in nodes) {
      updatedNodes[nodeId] = Object.assign(HigJS.obj.clone(state.nodes[nodeId]) || {}, nodes[nodeId]);
    }
    state.nodes = Object.assign(HigJS.obj.clone(state.nodes), updatedNodes);
  },

  UPDATE_NODES_ADV (state, nodes) {
    const updatedNodes = {};
    for (const nodeId in nodes) {
      updatedNodes[nodeId] = HigJS.obj.extendAdv(HigJS.obj.clone(state.nodes[nodeId]) || {}, nodes[nodeId]);
    }
    state.nodes = Object.assign(HigJS.obj.clone(state.nodes), updatedNodes);
  },

  UPDATE_PARAMETERS (state, newParameters) {
    for (const nodeId in newParameters) {
      state.parameters[nodeId] = Object.assign(state.parameters[nodeId] || {}, newParameters[nodeId]);
    }
  },

  UPDATE_CHILD_NODES_LOADED (state, childNodesLoaded) {
    state.childNodesLoaded = Object.assign(HigJS.obj.clone(state.childNodesLoaded), childNodesLoaded);
  },

  UPDATE_FULL_NODES_LOADED (state, fullNodesLoaded) {
    state.fullNodesLoaded = Object.assign(HigJS.obj.clone(state.fullNodesLoaded), fullNodesLoaded);
  },

  FLUSH_NODES (state, nodesIds) {
    nodesIds.forEach((nodeId) => {
      delete state.nodes[nodeId];
      delete state.childNodesLoaded[nodeId];
      delete state.parameters[nodeId];
    });
  },

  LOADED_ALL_NODES (state) { state.allNodesLoaded = true; },

  UPDATE_PLANTS_ADV (state, plants) {
    const updatedPlants = {};
    for (const plantId in plants) {
      updatedPlants[plantId] = HigJS.obj.extendAdv(HigJS.obj.clone(state.plantInfoLoaded) || {}, plants[plantId]);
    }
    state.plantInfoLoaded = Object.assign(HigJS.obj.clone(state.plantInfoLoaded), updatedPlants);
  }
};

// actions
const actions = {
  async loadAllNodes () {
    console.warn('loadAllNodes is deprecated, please use this.$getAllNodes');

    const baseNodes = await Nodes.getAllUserNodes(this);
    await Nodes.getNodesFullCaching(this, Object.keys(baseNodes));
  },

  async loadNodes (context, { nodesIds, loadChilds }) {
    console.warn('loadNodes is deprecated, please use this.$getNodes');

    const baseNodes = await Nodes.getNodesCaching(this, nodesIds, loadChilds);
    await Nodes.getNodesFullCaching(this, Object.keys(baseNodes));
  },

  loadNodeTypes () { console.warn('loadNodeTypes is deprecated, please use this.$getNodeTypes'); },
  loadVarPeriods () { console.warn('loadVarPeriods is deprecated, please use this.$getVarPeriods'); },
  loadVarTypes () { console.warn('loadVarTypes is deprecated, please use this.$getVarTypes'); }
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
};
