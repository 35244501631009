<template>
  <teleport to="body">
    <VI-Scroll-Div @click.stop="$emit( 'close' )" noHScroll noVScroll class="background">
      <VI-Scroll-Div @click.stop noHScroll noVScroll class="modal">
        <NotesList v-if="boolShowNotes" :notes="listNotes" @close="toggleShowNotes" @removeNote="tempRemoveNote" @save="applyNoteChanges" />
        <div class="titleContainer">
          <VI-Label :text="label"/>
          <div @click="$emit( 'close' )">
            <VI-Icon :type="'times-circle'" class="close-icon"/>
          </div>
        </div>
        <div class="optionsContainer">
          <VI-Input :placeholder="translate( 'search' )" @keyup="changeSearch"/>
          <div class="onlyActiveSwitch">
            <VI-Label class="spaced" :text="translate( 'onlyActive' )"/>
            <VI-Checkbox v-model="active"/>
          </div>
          <VI-Rangepicker v-model:value="dataInterval" dropdowns opens="left"/>
        </div>
        <div class="tableContainer">
          <table class="table">
            <thead>
              <th >
                <VI-Checkbox class="centered" v-model="allSelected"/>
              </th>
              <th v-for="(head, index) of renderedHeaders" :key="head.id" >
                <VI-Label :text="translate( head.label )" class="header" @click="changeSort(index)"/>
              </th>
            </thead>
            <tbody>
              <template v-for="el of renderedAlarms" :key="el.idLogAllarmi" >
                <AlarmRow :alarm="el" :dataInterval="dataInterval" @selected="toggleSelection" @showNotes="showNotes"/>
              </template>
            </tbody>
          </table>
        </div>
        <div class="buttonsContainer">
          <div class="loading">
            <template v-if="loading">
              <VI-Icon :type="'spinner-third'" spin class="spinner"/>
              <VI-Label :text="translate( 'updatingData' )" class="text"/>
            </template>
          </div>
          <div>
            <VI-Button class="buttons" :label="translate( 'createIntervention' )" @click="createTicket"/>
            <VI-Button class="buttons" :label="translate( 'export' )" @click="listExport"/>
            <VI-Button class="buttons" :label="translate( 'takeVision' )" @click="takeVision"/>
          </div>
        </div>
      </VI-Scroll-Div>
    </VI-Scroll-Div>
  </teleport>
  <teleport to="body" v-if="showTicketCreation">
    <VI-Scroll-Div @click.stop="closeTicketWindow" noHScroll noVScroll class="background ticket">
      <VI-Scroll-Div @click.stop noHScroll noVScroll class="modal">
        <div class="titleContainer">
          <VI-Label :text="translate( 'pvManAddTicket' )"/>
          <div @click="closeTicketWindow">
            <VI-Icon :type="'times-circle'" class="close-icon"/>
          </div>
        </div>
        <div class="iframeContainer">
          <iframe :src="ticketUrl"></iframe>
        </div>
        </VI-Scroll-Div>
      </VI-Scroll-Div>
  </teleport>
</template>

<script>
/* global edw, visionConf, HigJS, pageConf, userConf */

import { DateTime } from 'luxon';
import VIIcon from '../VI-Icon.vue';
import AlarmRow from './AlarmRow.vue';
import VIInput from '../VI-Input.vue';
import VILabel from '../VI-Label.vue';
import Utils from '../../libs/utils';
import VIButton from '../VI-Button.vue';
import NotesList from './Notes-List.vue';
import VICheckbox from '../VI-Checkbox.vue';
import VIScrollDiv from '../VI-Scroll-Div.vue';
import VIRangepicker from '../VI-Rangepicker.vue';

const defaultHeaders = [
  { id: 'statoAlr', label: 'state' },
  { id: 'plantLabel', label: 'plant' },
  { id: 'nodeLabel', label: 'origin' },
  { id: 'nomeAlr', label: 'name' },
  { id: 'livelloNomeId', label: 'level' },
  { id: 'start', label: 'lastOccurrence' },
  { id: 'note', label: 'notes' }
];

const AlarmsList = {
  name: 'VI-AlarmsList',
  components: {
    VIScrollDiv,
    VIRangepicker,
    VIButton,
    VIInput,
    VILabel,
    VICheckbox,
    AlarmRow,
    VIIcon,
    NotesList
  },
  props: {
    headers: { type: Array },
    withselection: { type: Boolean, default: false },
    nodeIds: { type: [Array, String] },
    impiantiId: { type: [String, Number] },
    label: { type: String },
    levelType: { type: String },
    level: { type: Array },
    onlyActive: { type: Boolean, default: true }
  },
  provide () {
    return {
      enabledSelection: this.withselection,
      headers: this.renderedHeaders
    };
  },
  emits: ['close'],
  data () {
    return {
      conf: visionConf || {},
      renderedAlarms: [],
      cachedAlarms: [],
      renderedHeaders: this.headers || defaultHeaders,
      dataInterval: Utils.interval('currDay'),
      allSelected: false,
      loading: true,
      alarmsDaemon: undefined,
      active: this.onlyActive,
      alarmOfNotes: {},
      boolShowNotes: false,
      listNotes: [],
      idRemovedNotes: [],
      sort: {
        by: 'statoAlr',
        asc: true
      },
      search: '',
      selectionList: new Set(),
      showTicketCreation: false,
      ticketUrl: undefined
    };
  },
  computed: {

  },
  watch: {
    search: function () {
      this.renderedAlarms = sortAndSearch.call(this, this.cachedAlarms, this.sort, this.search, this.active);
    },
    active: function () {
      this.renderedAlarms = sortAndSearch.call(this, this.cachedAlarms, this.sort, this.search, this.active);
    },
    nodeIds: function () {
      // console.log( this.nodeIds );
    },
    allSelected: function () {
      let alarm;
      this.selectionList.clear();
      const status = Boolean(this.allSelected);

      for (alarm of this.renderedAlarms) {
        this.toggleSelection(alarm.idLogAllarmi, Boolean(this.allSelected));
        alarm.selected = status;
      }

      return status;
    },
    cachedAlarms: function () {
      this.renderedAlarms = sortAndSearch.call(this, this.cachedAlarms, this.sort, this.search, this.active);
    },
    dataInterval: function () {
      this.startDaemon();
    },
    sort: {
      deep: true,
      handler: function () {
        this.renderedAlarms = sortAndSearch.call(this, this.cachedAlarms, this.sort, this.search, this.active);
      }
    }
  },
  methods: {
    translate: translate,
    takeVision: takeVision,
    listExport: listExport,
    createTicket: createTicket,
    closeTicketWindow () {
      this.showTicketCreation = false;
      this.ticketUrl = undefined;
    },
    toggleSelection (id, value) {
      if (!id) return;
      id = String(id);
      if (value) {
        this.selectionList.add(id);
      } else {
        this.selectionList.delete(id);
      }
    },
    tickDaemon: function () {
      this.alarmsDaemon && this.alarmsDaemon.tickNow();
    },
    changeSort (index) {
      if (this.sort.by === this.renderedHeaders[index].id) {
        this.sort.asc = !this.sort.asc;
      } else {
        this.sort.by = this.renderedHeaders[index].id;
        this.sort.asc = true;
      }
    },
    changeSearch (value) {
      this.search = value;
    },
    startDaemon () {
      if (!this.alarmsDaemon) {
        this.alarmsDaemon = new Utils.Daemon(getAlarms.bind(this), 60000);
      }
      this.alarmsDaemon.stop();
      this.alarmsDaemon.start();
    },
    showNotes (alarm) {
      this.listNotes = [];
      this.alarmOfNotes = alarm;
      getNotes.call(this, this.alarmOfNotes);
    },
    toggleShowNotes () {
      this.boolShowNotes = !this.boolShowNotes;
      this.listNotes = [];
    },
    tempRemoveNote (note) {
      this.idRemovedNotes.push(note.id);
      const index = this.listNotes.findIndex(function (el) { return note.id === el.id; });
      this.listNotes.splice(index, 1);
    },
    applyNoteChanges (notes) {
      if (!Array.isArray(notes)) return;

      if (this.idRemovedNotes.length) {
        removeNotes(this.idRemovedNotes);
      }

      notes = notes.filter(function (el) { return el.isChanged; });
      return setNotes(this.alarmOfNotes, notes).then(() => {
        return this.showNotes(this.alarmOfNotes);
      });
    }
  },
  mounted () {
    this.startDaemon();
  },
  unmounted () {
    this.alarmsDaemon.stop();
  }
};

function translate (id) {
  return edw(id);
}

function takeVision () {
  // we need a method to
  // get selected alarms;
  let tmp, alr;
  const reqData = [];

  if (!this.selectionList.size) {
    HigJS.DOM.alert(edw('selectAtLeastOne'));
    return Promise.resolve();
  }

  for (alr of this.cachedAlarms) {
    if (!this.selectionList.has(String(alr.idLogAllarmi))) continue;
    tmp = null;

    if (alr.idAlr && alr.idDispositivi) {
      tmp = {
        idAlr: alr.idAlr,
        idDispositivi: alr.idDispositivi
      };
    } else if (alr.nodeId && alr.idDispositivi) {
      tmp = {
        nodeId: alr.nodeId,
        idDispositivi: alr.idDispositivi
      };
    }

    tmp && reqData.push(tmp);
  }

  if (!reqData.length) return Promise.resolve();

  const reqs = [{
    act: 'setDismissAlarms',
    idReq: 'dismissAlarms',
    data: { dismiss: 1, alarms: reqData }
  }];

  this.loading = true;
  this.alarmsDaemon && this.alarmsDaemon.stop();

  return Utils.ajaxRequest(reqs, '/node').catch((err) => {
    console.log(err);
  }).finally(() => {
    this.tickDaemon();
  });
}

function listExport () {
  HigJS.forms.startLoader();

  HigJS.ajax.downloadFile({
    reqs: [{
      act: 'exportAlarms',
      idReq: 'exportAlarms',
      data: {
        start: parseInt(this.dataInterval.start / 1000),
        stop: parseInt(this.dataInterval.stop / 1000),
        impiantoId: this.impiantiId || null,
        levelType: this.levelType,
        activeAll: 1,
        onlyActive: this.active ? 1 : 0,
        description: 1,
        langId: userConf.langId,
        records: true,
        fileName: edw('exAlrmFile'),
        levelsAlr: this.level.filter(el => el !== 'all').filter(el => !el.startsWith('!')),
        notLevelsAlr: this.level.filter(el => el !== 'all').filter(el => el.startsWith('!')).map(el => el.substring(1, el.length))
      }
    }],
    ids: userConf.ids,
    onSuccess: function () {
      HigJS.forms.stopLoader();
    },
    onError: function () {
      HigJS.forms.stopLoader();
      HigJS.DOM.alert(edw('pvManErrorOnDownloadFile'));
    },
    url: '/node',
    nodeJs: true
  });
}

function createTicket () {
  let nodeId, start, stop, alr;
  const alarms = [];
  nodeId = false;

  this.showTicketCreation = true;

  for (alr of this.cachedAlarms) {
    if (!this.selectionList.has(String(alr.idLogAllarmi))) continue;

    if (nodeId === false) {
      nodeId = alr.nodeId;
    } else if (nodeId !== alr.nodeId) {
      nodeId = 'various';
    }

    if (start === undefined) {
      start = alr.start;
      stop = alr.stop;
    } else {
      alr.start < start && (start = alr.start);
      alr.stop && alr.stop > stop && (stop = alr.stop);
    }

    alarms.push(alr.idLogAllarmi);
  }

  let pageUrl;
  pageUrl = '../../plugins/mccTicket/' + userConf.pkgListByType.mccTicketPl.version + '/newTicket.html?alarms=' + HigJS.obj.toString(alarms);

  if (nodeId && nodeId !== 'various') {
    pageUrl += '&nodeId=' + nodeId;
  }

  if (start) {
    pageUrl += '&start=' + start + '&stop=' + stop;
  }

  this.ticketUrl = pageUrl;

  pageConf.closeTicketWindow = () => {
    this.closeTicketWindow();
    this.showTicketCreation = false;
    this.ticketUrl = undefined;
  };
}

function getAlarms (resolve) {
  const reqs = [];
  const req = {
    act: 'getNodeAlarms',
    data: {
      start: parseInt(this.dataInterval.start / 1000),
      stop: parseInt(this.dataInterval.stop / 1000),
      levelType: this.levelType,
      onlyActive: this.active ? 1 : 0,
      notes: true
    }
  };
  if (!this.nodeIds) {
    this.cachedAlarms = [];
    return resolve();
  }

  if (this.nodeIds !== 'all') {
    req.data.nodeIds = this.nodeIds;
    reqs.push(req);
  }

  this.loading = true;

  if (!reqs.length) {
    this.cachedAlarms = [];
    return resolve();
  }

  Utils.ajaxRequest(reqs, '/node').then((reply) => {
    if (Array.isArray(reply)) {
      this.cachedAlarms = reply.flat();
    } else {
      this.cachedAlarms = [];
    }
    let alr;
    for (alr of this.cachedAlarms) {
      if (this.selectionList.has(String(alr.idLogAllarmi))) alr.selected = true;
    }

    if (this.level[0] !== 'all') {
      const removeArray = this.level.filter(el => el.startsWith('!'));
      const filterArray = this.level.filter(el => !el.startsWith('!'));

      for (const str of removeArray) {
        for (let i = this.cachedAlarms.length - 1; i >= 0; i--) {
          if (this.cachedAlarms[i].livelloAlr === str.substring(1)) this.cachedAlarms.splice(i, 1);
        }
      }

      if (filterArray.length) {
        for (let i = this.cachedAlarms.length - 1; i >= 0; i--) {
          if (!filterArray.includes(this.cachedAlarms[i].livelloAlr)) this.cachedAlarms.splice(i, 1);
        }
      }
    }

    window.listAlr = this.cachedAlarms;
    // console.log( this.cachedAlarms );
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    this.loading = false;
    resolve();
  });
}

function getNotes (alarm) {
  const req = {
    act: 'loadAlarmNotes',
    data: {
      idAlr: alarm.idAlr,
      idDispositivi: alarm.idDispositivi,
      nodeId: alarm.nodeId
    }
  };

  Utils.ajaxRequest([req], '/node').then((reply) => {
    this.listNotes = reply.flat().reverse();
    this.boolShowNotes = true;
  });
}

function setNotes (alarm, notes) {
  let r, n;
  const reqs = [];
  !Array.isArray(notes) && (notes = [notes]);

  for (n of notes) {
    let utc;
    utc = DateTime.fromSeconds(n.utc);
    utc = n.utc - utc.offset * 60;

    r = {
      act: 'setAlarmNote',
      data: {
        idAlr: alarm.idAlr,
        idDispositivi: alarm.idDispositivi,
        nodeId: alarm.nodeId,
        note: n.note,
        userId: n.userId,
        utc: utc
      }
    };

    if (!isNaN(n.id) && n.id > 0) r.data.id = n.id;

    reqs.push(r);
  }

  if (!reqs.length) return Promise.resolve();

  HigJS.forms.startLoader();

  return Utils.ajaxRequest(reqs, '/node').catch((err) => {
    console.log(err);
  }).finally(() => {
    HigJS.forms.stopLoader();
  });
}

function removeNotes (ids) {
  !Array.isArray(ids) && (ids = [ids]);
  const req = {
    act: 'removeAlarmNotes',
    data: { ids: ids }
  };
  return Utils.ajaxRequest([req], '/node');
}

function sortAndSearch (list, sort, search, active) {
  if (search !== '') {
    const genFilter = search.toLowerCase();
    list = list.filter((item) => {
      let el;
      for (el in item) {
        if (String(item[el]).toLowerCase().includes(genFilter)) return true;
      }
      return false;
    });
  }

  if (active) {
    list = list.filter((item) => {
      if (item.statoAlr === 'ALRM_ATTIVO') return true;
      return false;
    });
  }

  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  list.sort((a, b) => {
    let _sort;
    _sort = collator.compare(String(a[sort.by]), String(b[sort.by]));

    // sort by lastUpdatedDate if comparison upon statoAlr return 0 (a.k.a. keep element in the current position)
    if (sort.by === 'statoAlr' && !_sort) {
      _sort = collator.compare(String(a.lastUpdatedDate), String(b.lastUpdatedDate));
      if (sort.asc) {
        _sort = -_sort;
      }
    }

    return _sort;
  });

  if (!sort.asc) {
    list.reverse();
  }

  return list;
}

export default AlarmsList;
</script>

<style scoped>
  .background {
    background: #00000080;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .background.ticket {
    z-index: 12;
  }

  .modal {
    min-height: 70%;
    max-height: 90%;
    max-width: 1500px;
    min-width: 80%;
    background: var( --color-primary );
    border-radius: 3px;
    display: flex;
    flex-flow: column;
  }

  .titleContainer {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    background: var( --color-primary-1 );
    padding: 8px 12px;
    font-size: 1.5em;
    align-items: center;
  }

  .optionsContainer {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 2px 5px;
    align-items: center;
  }

  .tableContainer {
    flex: 1 1 100%;
    height: 0;
    overflow: auto;
  }

  .iframeContainer {
    flex: 1 1 100%;
    height: 0;
    overflow: hidden;
  }

  table.table {
    width: 100%;
    flex: 1 1 100%;
    table-layout: auto;
    position: static;
    border-spacing: 0 2px;
    padding: 0 20px;
  }

  table.table tbody::before {
    content: '_';
    line-height: 0.1px;
    color: transparent;
    display: block;
  }

  table.table thead th {
    background-color: var(--color-primary-1);
    position: sticky;
    top: 0px;
    z-index: 10;
    height: 4em;
  }

  .buttonsContainer {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }

  .buttonsContainer > div {
    flex: 0 0 auto;
    padding: 8px 12px;
  }

  .buttonsContainer > div:first-of-type {
    flex: 1 1 100%;
  }

  .onlyActiveSwitch {
    display: flex;
    align-items: center;
  }

  .buttons {
    margin: 0 2px;
    min-width: 100px;
  }

  .loading {
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .loading .spinner {
    flex: 0 0 auto;
    font-size: 2em;
    color: var( --color-highlight );
  }

  .loading .text {
    font-size: 1.2em;
    padding: 5px 10px;
  }

  .close-icon {
    font-size: 1.2em;
    font-weight: bold;
    color: var( --color-error );
    cursor: pointer;
  }

  .header {
    cursor: pointer;
  }

  .iframeContainer iframe {
    width: 100%;
    height: 100%;
    outline: none;
    margin: 0;
    border: 0;
  }

  .spaced {
    padding-right: 10px;
  }

  .centered {
    margin: auto;
    background: var( --color-primary-2 ) !important;
  }
</style>
