<template>
  <transition name="fade">
    <div v-if="active" :class="['loader', { 'fixed': fixed }]">
      <div class="background"></div>
      <div class="loaderIconContainer">
        <VI-Icon class="loaderIcon bg" type="circle"/>
        <VI-Icon class="loaderIcon fg" type="spinner-third" spin/>
      </div>
    </div>
  </transition>
</template>

<script>
import VIIcon from './VI-Icon.vue';

export default {
  name: 'VI-Loader',
  components: {
    VIIcon
  },
  props: {
    active: { type: Boolean, default: false },
    fixed: { type: Boolean, default: false }
  }
};
</script>

<style scoped>
.loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.loader.fixed{
  position: fixed;
}

.background{
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  opacity: .8;
}

.loaderIconContainer{
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  font-size: 5em;
}

.loaderIcon{
  position: absolute;
  top: 0;
  left: 0;
}

.loaderIcon.bg{
  color: var(--color-primary-text);
}

.loaderIcon.fg{
  color: var(--color-highlight);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
