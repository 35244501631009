<template>
  <div :class="[ 'VILabel', { 'loading': loading, 'noText': $_text == '---' }]">{{$_text}}</div>
</template>

<script>
export default {
  name: 'VI-Label',
  props: {
    text: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    length: { type: Number, default: 20 }
  },
  computed: {
    $_text: function () {
      return this.loading ? new Array(this.length + 1).join('#') : (this.text ? this.text : '---');
    }
  }
};
</script>

<style scoped>
.VILabel{}

.VILabel.noText{
  opacity: 0;
}

.VILabel.loading{
  background: currentColor;
  border-radius: 5px;
  animation: pulseOpacity 3s ease-in-out infinite;
  opacity: .6;
}

@keyframes pulseOpacity {
  50%{
    opacity: .3;
  }
}
</style>
