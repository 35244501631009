import { marked } from 'marked';

const renderer = new marked.Renderer();
renderer.link = function (href, title, text) {
  return `<a target="_blank" href="${href}" title="${(title || '')}">${text}</a>`;
};
renderer.table = function (header, body) {
  return `<table class='table table-bordered table-striped'>${header}${body}</table>`;
};
marked.setOptions({ renderer: renderer });

export default marked;
