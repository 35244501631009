import Utils from '../utils/index.js';

function getActiveAlarms (plantId) {
  // query=[{"act":"getAlarms","idReq":"getAlarms","ids":"MhaTRWJGN5DA9RNmQeCM9rq5","DATI":{"start":1607558400,"stop":1607644799,"municipality":null,"operator":null,"structureType":null,"levelType":null,"description":false,"activeAll":1}}]

  return Utils.ajaxRequest({
    act: 'getAlarms',
    data: {
      start: null,
      stop: null,
      impiantoId: plantId,
      municipality: null,
      operator: null,
      structureType: null,
      levelType: null,
      description: true,
      activeAll: 1
    }
  }).then((alarms) => {
    return alarms.alarms || [];
  });
}

function getAlarms (start, stop) {
  return Utils.ajaxRequest({
    act: 'getAlarms',
    data: {
      start: start,
      stop: stop,
      municipality: null,
      operator: null,
      structureType: null,
      levelType: null,
      description: true,
      activeAll: 1
    }
  }).then((alarms) => {
    return alarms.alarms || [];
  });
}

export default {
  getActiveAlarms,
  getAlarms
};
