<template>
  <div :id="id" :class="[ 'VISunburstNavigator', { 'loading': loading } ]" ref="container" v-bind="$attrs"></div>
  <VI-Placeholder :loading="loading" :component="containerRef" round/>
</template>

<script>
import VIPlaceholder from './VI-Placeholder.vue';
/* A lot of the things in this object are made to bypass some Highcharts problems and limitations */

/* global Highcharts, $ */

export default {
  name: 'VI-Sunburst-Navigator',
  components: {
    VIPlaceholder
  },
  props: {
    items: { type: Array, default: () => { return []; } },
    itemsValues: { type: Object, default: () => { return {}; } },
    itemsColors: { type: Object, default: () => { return {}; } },
    maxLevels: { type: Number, default: 2 },
    selected: { type: [Number, String], default: undefined },
    loading: { type: Boolean, default: false },
    fillDunutHole: { type: Boolean, default: false }

    // items:[
    //     { id: 23, name: "", parent: '' },
    //     ....
    // ]
  },
  data () {
    let uniqueId;
    do {
      uniqueId = 'chart_' + (Math.random() * 1000000000);
    } while (document.getElementById(uniqueId) != null);

    return {
      id: uniqueId,
      curSelected: undefined,
      chart: {},
      colorIndex: 0,
      containerRef: undefined,
      setDataTo: undefined,
      updatePointsTo: undefined,
      newList: true
    };
  },
  computed: {
    sunburstData: function () {
      const isParent = {};

      let ret = [];

      if (!this.fillDunutHole) {
        ret.push({ id: '-', name: null, color: '#FFF0', dataLabels: { enabled: false }, className: 'hidden' });
      }

      ret = ret.concat([...this.items.map((item, i) => {
        isParent[String(item.parent) || '-'] = true;

        return [
          String(item.id),
          String(item.parent) || (this.fillDunutHole ? undefined : '-'),
          item.name,
          this.itemsValues[item.id],
          this.itemsColors[item.id] ? this.itemsColors[item.id] : 'var(--chart-color-' + ((i % 20) + 1) + ')',
          {
            click: this.rootChanged
          }
        ];
      }), ...this.items.reduce((acc, item) => {
        if (!isParent[item.id]) {
          acc.push([
            item.id + '_',
            String(item.id),
            null,
            1,
            '#FFF0',
            {},
            { enabled: false },
            'hidden'
          ]);
        }
        return acc;
      }, [])]);

      return ret;
    },
    performanceSaver: function () {
      return !!(Array.isArray(this.sunburstData) && this.sunburstData.length > 199);
    }
  },
  watch: {
    items: function () {
      if (!this.chart) { return; }

      this.curSelected = undefined;
      !this.newList && this.chart.series[0].update({ rootId: undefined }); // when updating the items set the root id to undefined to avoid some errors caused by the sunburst
      !this.newList && this.chart.series[0].update({ animation: false });
      this.setData();
    },
    itemsValues: function () { this.setData(); }, // when updating the values there is no need to change the root id
    itemsColors: function () { this.setData(); }, // when updating the colors there is no need to change the root id
    maxLevels: function () {
      if (!this.chart) { return; }
      const maxLevels = (this.fillDunutHole ? this.maxLevels - 1 : this.maxLevels);

      this.chart.series[0].update({
        levels: [
          {
            level: 1,
            levelSize: { unit: 'weight', value: 1 }
          },
          {
            level: 2,
            levelSize: { unit: 'weight', value: 1 }
          }
        ].concat((() => { const ret = []; for (let i = 2; i < 100; i++) { ret.push({ level: i + 1, levelSize: { unit: 'weight', value: i <= maxLevels ? 1 : 0 }, dataLabels: { enabled: i <= maxLevels } }); } return ret; })())
      });

      this.chart.series[0].update({ animation: this.performanceSaver ? false : { duration: 400 } }); // This solves a bug that disables animations afer an update, this is specific for sunburst (or at least i never encountered it anywhere else)
    },
    selected: function () {
      const realSelected = this.getRealSelected();
      if (!this.chart || String(realSelected) === String(this.curSelected) || !this.chart.series[0].hasData()) { return; } // when using v-model on "selected" this is called every time a user changes the root node, to block that it returns if the value is the same as the curSelected

      this.chart.series[0].update({ rootId: realSelected });
      this.chart.series[0].update({ animation: this.performanceSaver ? false : { duration: 400 } }); // This solves a bug that disables animations afer an update, this is specific for sunburst (or at least i never encountered it anywhere else)

      this.listenBackButton();
    }
  },
  mounted () {
    // console.time('sunburst');
    this.drawChart();
    !this.newList && this.chart.series[0].setData(this.sunburstData, true, true);

    this.$nextTick(() => { this.containerRef = this.$refs.container; });
  },
  methods: {
    setData () {
      clearTimeout(this.setDataTo);
      this.setDataTo = setTimeout(() => {
        this.chart.series[0].setData(this.newList ? this.sunburstData.slice(0, 100) : this.sunburstData, true, true);
        // console.timeEnd('sunburst');
        this.newList && this.updateSerieData();
        this.newList && (this.newList = false);
      }, 500);
    },
    updateSerieData () {
      clearTimeout(this.updatePointsTo);
      this.updatePointsTo = setTimeout(() => {
        this.chart.series[0].setData(this.sunburstData, true, true);
      }, 1400);
    },
    rootChanged () {
      this.curSelected = this.chart.series[0].rootNode; // this seems to work but dunno if that's the right way
      this.$emit('update:selected', this.curSelected);
      this.$emit('selected', this.curSelected);
      this.listenBackButton();
    },
    listenBackButton () {
      const backButton = $('.highcharts-button.highcharts-drillup-button'); // I REALLY HATE TO PUT jQuery HERE BUT IS THE SAFEST/FASTEST WAY TO DO IT, WILL CHANGE THAT IN FUTURE
      backButton.off('click.rootChanged').on('click.rootChanged', this.rootChanged.bind(this));
    },
    drawChart () {
      const maxLevels = (this.fillDunutHole ? this.maxLevels - 1 : this.maxLevels);

      this.chart = Highcharts.chart(this.id, {
        chart: { height: '100%', backgroundColor: 'none', margin: [0, 0, 0, 0] },
        title: { text: '' },
        subtitle: { text: '' },
        credits: { enabled: false },
        plotOptions: {
          series: {
            allowTraversingTree: true,
            levelIsConstant: false,
            animation: this.performanceSaver ? false : { duration: 400 },
            turboThreshold: 10000
          }
        },
        series: [{
          type: 'sunburst',
          keys: ['id', 'parent', 'name', 'value', 'color', 'events', 'dataLabels', 'className'],
          data: [],
          cursor: 'pointer',
          borderWidth: 1,
          borderColor: '#FFFFFF00',
          animationLimit: Infinity,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            // filter: {
            //     property: 'innerArcLength',
            //     operator: '>',
            //     value: 16
            // },
            rotationMode: 'circular'
          },
          states: this.performanceSaver ? { inactive: { enabled: false }, normal: false, select: { enabled: false } } : { inactive: { opacity: 1 } },
          levels: [
            {
              level: 1,
              levelSize: { unit: 'weight', value: 1 }
            },
            {
              level: 2,
              levelSize: { unit: 'weight', value: 1 }
            }
          ].concat((() => { const ret = []; for (let i = 2; i < 100; i++) { ret.push({ level: i + 1, levelSize: { unit: 'weight', value: i <= maxLevels ? 1 : 0 }, dataLabels: { enabled: i <= maxLevels } }); } return ret; })())
        }],
        tooltip: {
          headerFormat: '',
          formatter: function () {
            return this.point.id !== '-' ? this.point.name : false;
          }
        }
      });
    },
    reflow () {
      this.chart.reflow();
    },
    getRealSelected () {
      if (this.items.find((el) => { return String(el.id) === String(this.selected); })) {
        return String(this.selected);
      }
      return undefined;
    }
  }
};
</script>

<style scoped>
.VISunburstNavigator{}

.VISunburstNavigator.loading{
  opacity: 0;
}

::v-deep(.highcharts-point.hidden){
  display: none;
}
</style>
