<template>
  <div class="VITableCell" ref="cell" v-on:click="cellClicked">
    <div class="stdCell" v-if="type == 'std'">{{$_value}}</div>
    <VI-Input class="inputCell" v-if="type == 'input'" :value="$_value" @update="updateValue" />
    <VI-Select class="selectCell" v-if="type == 'select'" :value="$_value" @update="updateValue" :options="selectOptions"/>
    <div class="checkCell" v-if="type == 'checkbox'">
      <!-- <VI-Icon :type="$_value == true ? 'check-square' : 'square'" :class="['checkIcon', { 'checked': $_value == true }]" v-on:click="checkboxClick"/> -->
      <VI-Checkbox :modelValue="$_value" @update:modelValue="checkboxClick"/>
    </div>
    <div class="radioCell" v-if="type == 'radio'">
      <VI-Icon :type='$_value == true ? "dot-circle" : "circle"' :class="['radioIcon', { 'checked': $_value == true }]" v-on:click="checkboxClick"/>
    </div>
    <div class="iconButtonCell" v-if="type == 'iconButton'">
      <VI-Icon class="iconButtonIcon" :type="icon" v-on:click="buttonClick"/>
    </div>
    <div class="buttonCell" v-if="type == 'button'">
      <VI-Button :label="buttonLabel" v-on:click="buttonClick"/>
    </div>
  </div>
</template>

<script>
import VIInput from './VI-Input.vue';
import VISelect from './VI-Select.vue';
import VIIcon from './VI-Icon.vue';
import VIButton from './VI-Button.vue';
import VICheckbox from './VI-Checkbox.vue';

export default {
  name: 'VI-Table-cell',
  components: {
    VIInput,
    VISelect,
    VIIcon,
    VIButton,
    VICheckbox
  },
  props: {
    value: { type: [String, Number, Boolean], default: '' },
    type: { type: String, default: 'std' },
    icon: { type: String, default: 'question-circle' }, // used for iconButton
    buttonLabel: { type: String, default: '' },
    selectOptions: { type: Array, default: () => { return []; } } // used for select
  },
  computed: {
    $_value: function () {
      let parsedVal = this.value;
      if (this.type === 'checkbox' || this.type === 'radio') {
        parsedVal = !!parsedVal;
      }
      return parsedVal;
    }
  },
  methods: {
    updateValue (newVal) {
      this.$emit('update:value', newVal);
      this.$emit('update', newVal);
    },
    cellClicked () {
      this.$emit('cell-click');
    },
    checkboxClick () {
      this.updateValue(!this.$_value);
    },
    buttonClick () {
      this.$emit('button-click');
    }
  }
};
</script>

<style scoped>
.VITableCell{
  width: 100%;
  text-align: center;
  border: none;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.VITableCell > *{
  width: auto;
  min-width: 0;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.VITableCell > .stdCell{
  padding: 0 3px;
}

.VITableCell > .inputCell{
  min-width: 100px;
  width: 100%;
  padding: 0px 2px;
}

.VITableCell > .selectCell{
  min-width: 100px;
  width: 100%;
  padding: 0px 2px;
}

/* .VITableCell > .checkCell, */
.VITableCell > .radioCell{
  font-size: 2em;
}

/* .VITableCell > .checkCell > .checkIcon, */
.VITableCell > .radioCell > .radioIcon{
  cursor: pointer;
}

/* .VITableCell > .checkCell > .checked, */
.VITableCell > .radioCell > .checked{
  color: var(--color-highlight);
}

.VITableCell > .iconButtonCell{
  font-size: 2em;
}

.VITableCell > .iconButtonCell > .iconButtonIcon{
  cursor: pointer;
}

.VITableCell > .iconButtonCell > .iconButtonIcon:hover{
  color: var(--color-highlight);
}

.VITableCell > .buttonCell{ }

</style>
