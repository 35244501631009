<template>
  <i :class="[ 'VIIcon', fastyle, iconType, { 'fa-spin': spin }]"></i>
</template>

<script>
export default {
  name: 'VI-Icon',
  props: {
    type: { type: String, default: '' },
    spin: { type: Boolean, default: false },
    fas: { type: Boolean, default: false },
    fad: { type: Boolean, default: false },
    far: { type: Boolean, default: false }
  },
  computed: {
    iconType: function () { return 'fa-' + this.type; },
    fastyle: function () {
      if (this.far) return 'far';
      if (this.fas) return 'fas';
      if (this.fad) return 'fad';
      return 'fal';
    }
  }
};
</script>

<style scoped>
  .VIIcon{
    font-size: 1em;
  }
</style>
