<template>
  <teleport to="body">
    <VI-Scroll-Div @click.stop="$emit( 'close' )" noHScroll noVScroll class="background">
      <VI-Scroll-Div @click.stop noHScroll noVScroll class="modal">
        <slot></slot>
      </VI-Scroll-Div>
    </VI-Scroll-Div>
  </teleport>
</template>

<script>
import VIScrollDiv from './VI-Scroll-Div.vue';

export default {
  name: 'VI-Dialog-Div',
  components: {
    VIScrollDiv
  },
  emits: ['close']
};
</script>

<style scoped>
  .background {
    background: #00000080;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    z-index: 98;
  }

  .modal {
    background: var( --color-primary );
    border-radius: 3px;
    display: flex;
    flex-flow: column;
    margin: 20px;
    border-radius: 10px;
  }
</style>
