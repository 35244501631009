<template>
  <button class="button">
    <template v-if="label">{{label}}</template>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  name: 'VI-Button',
  props: {
    label: { type: String, default: undefined }
  },
  methods: {}
};
</script>

<style scoped>
.button{
  outline: none;
  height: 31px;
  width: auto;
  border-radius: 16px;
  /* border: 2px solid var(--color-highlight); */
  border: none;
  background-color: var(--color-highlight);
  color: var(--color-highlight-text);
  padding: 0 10px;
  cursor: pointer;
}

.button:hover{
  background-color: var(--color-highlight-3);
  color: var(--color-highlight-text);
}

.button:active{
  background-color: var(--color-highlight-text);
  color: var(--color-highlight);
}
</style>
