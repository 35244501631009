<template>
  <tr v-if="alarm" @click="loadChart" :class="[{ dismissedAlr: isDismissed }, statusClass]" >
    <td class="cell" @click.stop><VI-Checkbox class="centered" v-model:modelValue="alarm.selected" @update:modelValue="onSelection"/></td>
    <td class="cell" v-for="head of headers" :key="head.id + '_' + alarm.id">
      <VI-Icon class="scaledIcon" v-if="head.id == 'statoAlr'" :class="computedAlarm[ head.id ].class" :type="computedAlarm[ head.id ].icon"/>
      <div @click.stop="openNotes" v-else-if="head.id == 'note'">
        <VI-Icon class="scaledIcon clickable" :type="computedAlarm[ head.id ].icon" :style="{ color: computedAlarm[ head.id ].color }"/>
      </div>
      <p v-else>{{ translate(computedAlarm[ head.id ]) }}</p>
    </td>
  </tr>
  <tr class="details" v-if="details" >
    <td :colspan="headers.length + 1">
      <div class="wrapper">
        <div class="graph">
          <VI-Chart class="chart" ref="chart" title='' subtitle='' :series='chartConf.series' :data='chartConf.data' :options='chartConf.options' :yAxis='chartConf.yAxis' :xAxis='chartConf.xAxis' />
        </div>
        <div class="details-data">
          <p>{{ edw.origin + ': ' + (alarm.deviceLbl || alarm.dataloggerLbl || edw.kpiOrigin + " - " + alarm.nodeLabel )}}</p>
          <p>{{ edw.state + ': ' + computedAlarm.statoAlr.label}}</p>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
/* global edw, HigJS */

import { DateTime } from 'luxon';
import VIIcon from '../VI-Icon.vue';
import VIChart from '../VI-Chart.vue';
import VICheckbox from '../VI-Checkbox.vue';

const AlarmRow = {
  name: 'AlarmRow',
  components: {
    VICheckbox,
    VIIcon,
    VIChart
  },
  inject: ['enabledSelection', 'headers'],
  props: {
    alarm: { type: Object },
    dataInterval: { type: Object }
  },
  emits: ['selected', 'showNotes'],
  data () {
    return {
      details: false,
      selected: false,
      chartConf: {
        series: [],
        data: {},
        options: {},
        yAxis: {},
        xAxis: {}
      },
      edw: {
        origin: edw('origin'),
        state: edw('state'),
        active: edw('active'),
        kpiOrigin: edw('kpiOrigin')
      }
    };
  },
  computed: {
    computedAlarm: function () {
      const alarm = HigJS.obj.clone(this.alarm);
      for (const header of this.headers) {
        if (header.id === 'statoAlr') {
          alarm[header.id] = this.setAlarmIcon(alarm[header.id]);
        }
        if (header.id === 'livelloNomeId') {
          if (alarm.livelloLblId) {
            alarm.livelloLblId = alarm.livelloLblId.replace('{', '');
            alarm.livelloLblId = alarm.livelloLblId.replace('}', '');
            alarm[header.id] = this.setLevel(alarm.livelloLblId);
          }
        }
        if (header.id === 'start') {
          let lastOccurrence = null;
          if (alarm.occurrences && Array.isArray(alarm.occurrences)) {
            lastOccurrence = alarm.occurrences.sort((a, b) => b[0] - a[0]).find((el) => el[1] === 1);
          }
          alarm[header.id] = lastOccurrence[0] ? this.setTime(lastOccurrence[0]) : null;
          // alarm[header.id] = this.setTime(alarm[header.id]);
        }
        if (header.id === 'note') {
          alarm[header.id] = this.setNote();
        }
        if (header.id === 'nodeLabel') {
          alarm[header.id] = this.setNodeLabel(alarm[header.id]);
        }
      }
      return alarm;
    },
    statusClass () {
      if (this.alarm.statoAlr === 'ALRM_ATTIVO') {
        return 'activeAlr';
      }
      return 'subsidedAlr';
    },
    isDismissed () {
      if (Number(this.alarm.dismissed) === 1) return true;
      return false;
    }
  },
  watch: {
    computedAlarm: function () {
    }
  },
  methods: {
    onSelection (value) {
      this.$emit('selected', this.alarm.idLogAllarmi, value);
    },
    translate: translate,
    setAlarmIcon (obj) {
      if (obj === 'ALRM_ATTIVO') return { class: 'alrActive', icon: 'exclamation-triangle', label: edw('aAttivo') };
      else if (obj === 'ENDED_ALARM') return { class: 'alrEnded', icon: 'check', label: edw('aRientr') };
      else if (obj === 'ENDED_BLAK_OUT_ALARM') return { class: 'alrStopRest', icon: 'exclamation-circle', label: edw('aEndRestart') };
      else if (obj === 'ENDED_STOP_ACQ_ALARM') return { class: 'alrStopRest', icon: 'exclamation-circle', label: edw('aEndStop') };
      else if (obj === 'ENDED_ALARM_NO_REFRESH') return { class: 'alrEnded', icon: 'check', label: edw('aRientr') };
      else if (obj === 'ENDED_CAMBIO_COD') return { class: 'alrEnded', icon: 'exclamation-circle', label: edw('changedCode') };
      else if (obj === 'ENDED_NO_LINK_ALARM') return { class: 'alrNoLink', icon: 'exclamation-circle', label: edw('aEndNoLink') };
    },
    setLevel (obj) {
      return HigJS.diz.edwCustom(obj);
    },
    setTime (obj) {
      return HigJS.num.formatDate({ date: true, time: true, utc: obj });
    },
    setNote () {
      if (!this.alarm.notes) {
        return { icon: 'clipboard', color: 'var(--color-secondary-text)' };
      }
      return { icon: 'clipboard', color: 'var(--color-warning)' };
    },
    setNodeLabel (alarmHeaderId) {
      if (this.alarm.dataloggerLbl && this.alarm.deviceLbl) {
        return this.alarm.dataloggerLbl + ' - ' + this.alarm.deviceLbl;
      } else {
        return alarmHeaderId;
      }
    },
    loadChart () {
      this.details = !this.details;
      if (this.details) {
        this.chartConf.series = [{
          id: 'error_frequency',
          marker: { enabled: false },
          step: 'left',
          name: ''
        }];
        this.chartConf.options = {
          colors: ['#FF0000'],
          chart: {
            type: 'line',
            step: 'left'
          },
          legend: {
            enabled: false
          },
          tooltip: {
            formatter: function () {
              const string = ['<b>' + HigJS.num.formatDate(this.x / 1000) + '</b>'];
              string.push('<b style="font-weight:normal">' + edw('state') + ': ' + (Number(this.y) === 1 ? edw('active') : edw('inactive')) + '</b>');
              return string.join('<br>');
            },
            shared: true
          }
        };
        this.chartConf.yAxis = [{
          min: 0,
          max: 1,
          allowDecimals: false,
          labels: {
            formatter: function () { return Number(this.value) === 1 ? edw('active') : edw('inactive'); },
            style: {
              color: 'var(--color-highlight)'
            }
          },
          lineColor: 'var(--color-highlight)',
          lineWidth: 1
        }];
        this.chartConf.xAxis = [{
          softMin: this.dataInterval.start,
          softMax: this.dataInterval.stop,
          labels: {
            style: {
              color: 'var(--color-highlight)'
            }
          },
          lineColor: 'var(--color-highlight)',
          lineWidth: 1,
          tickColor: 'var(--color-highlight)',
          tickWidth: 1,
          units: [[
            'minute',
            [15, 30]
          ], [
            'hour',
            [1, 2, 3, 4, 6, 8, 12]
          ], [
            'day',
            [1, 3]
          ], [
            'week',
            [1]
          ], [
            'month',
            [1]
          ]
          ]
        }];
        this.chartConf.data.error_frequency = this.alarm.occurrences.map((v) => {
          return [v[0] * 1000, v[1]];
        });
        const local = DateTime.local().setZone(this.alarm.timeZone);
        const offset = local.offset;
        const timestamp = local.toMillis() + offset * 60 * 1000;
        if (this.alarm.statoAlr === 'ALRM_ATTIVO') {
          this.chartConf.data.error_frequency.push([timestamp, 1]);
          if (this.chartConf.data.error_frequency[0][0] > this.dataInterval.start) {
            this.chartConf.data.error_frequency.unshift([this.dataInterval.start, 0]);
          }
        } else {
          const now = DateTime.now().toMillis() + DateTime.now().offset * 60 * 1000;

          if (this.chartConf.data.error_frequency[this.chartConf.data.error_frequency.length - 1][0] < this.dataInterval.stop) {
            if (now < this.dataInterval.stop) {
              this.chartConf.data.error_frequency.push([now, 0]);
            } else {
              this.chartConf.data.error_frequency.push([this.dataInterval.stop, 0]);
            }
          }

          if (this.chartConf.data.error_frequency[0][0] > this.dataInterval.start) {
            this.chartConf.data.error_frequency.unshift([this.dataInterval.start, 0]);
          }
        }
      }
    },
    openNotes () {
      this.$emit('showNotes', this.alarm);
    }
  },
  mounted () {
    //
  }
};

function translate (id) {
  return edw(id);
}

export default AlarmRow;

</script>

<style scoped>
.alrActive {
  color: #FF0000;
}

.alrEnded {
  color: #009900;
}

.alrNoLink {
  color: #000099;
}

.alrStopRest {
  color: #990099;
}

tr:not(.details) {
  cursor: pointer;
}

tr.activeAlr:not(.details):not(.dismissedAlr) td {
  border-color: var(--color-error);
}

tr.subsidedAlr:not(.details):not(.dismissedAlr) td {
  border-color: var(--color-warning);
}

tr.dismissedAlr:not(.details) td {
  border-color: transparent ;
}

tr:not(.details) td{
  border-width: 2px 0;
  border-style: solid;
}

tr:not(.details) td:first-of-type {
  border-left-width: 2px;
  border-radius: 5px 0 0 5px;
}

tr:not(.details) td:last-of-type {
  border-right-width: 2px;
  border-radius: 0 5px 5px 0;
}

tr.details .wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  background-color: var( --color-secondary );
}

tr.details td {
  padding: 10px;
}

tr.details .graph {
  flex: 0 1 70%;
  height: 30vh;
}

tr.details .details-data {
  flex: 1 1 30%;
  color: var(--color-highlight);
}

.scaledIcon {
  font-size: 1.4em;
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.centered {
  margin: auto;
}
</style>
