<template>
  <div class="VITablePlus">
    <div class="VITablePlus_body">
      <table ref="table" class="VITablePlus_table" role="grid" cellspacing="0" cellpadding="0">
        <thead ref="head">
          <tr>
            <th v-for="(col, colI) in cols" :key="colI" :class="[{ 'sorting': col.sortable, 'sorting_asc': sort_ && sort_.col == col.name && sort_.dir == -1, 'sorting_desc': sort_ && sort_.col == col.name && sort_.dir == 1 }]" @click="col.sortable && toggleSort(col.name)" :width="col.width">
              <div class="headContentDiv">
                <slot :name="col.name+'-head'" :col="col.name" :lbl="col.label">
                  <slot name="head" :col="col.name" :lbl="col.label"> <!-- This slot is a backup of the outer one -->
                    <div class="headStdContent">{{col.label}}</div>
                  </slot>
                </slot>
              </div>
              <div class="headSortDiv">
                <VI-Icon :class="[ 'headSortIcon', { 'active': sort_ && sort_.col == col.name && sort_.dir != 0 } ]" :type="sort_ && sort_.col == col.name ? (sort_.dir < 0 ? 'sort-up' : sort_.dir > 0 ? 'sort-down' : 'sort') : 'sort'"/>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr ref="row-1" class="row-1"><td v-for="(col, colI) in cols" :key="colI"><slot :name="col.name" :val="undefined" :row="{}" :index="-1"><div class="cellStdContent">---</div></slot></td></tr>
          <slot name="table" :indexes="pageIndexes" :data="data">
            <tr v-for="rowI in pageIndexes" :key="rowI">
              <slot name="row" :row="data[rowI]" :index="rowI">
                <td v-for="(col, colI) in cols" :key="colI" @click="col.clickable && cellClick(rowI, col.name)" :style="{ 'cursor': col.clickable ? 'pointer' : 'auto' }">
                  <slot :name="col.name" :val="data[ rowI ][ col.name ]" :row="data[ rowI ]" :index="rowI" :col="col.name">
                    <slot name="cell" :val="data[ rowI ][ col.name ]" :row="data[ rowI ]" :index="rowI" :col="col.name"> <!-- This slot is a backup of the outer one -->
                      <div class="cellStdContent">{{data[ rowI ][ col.name ]}}</div>
                    </slot>
                  </slot>
                </td>
              </slot>
            </tr>
          </slot>
        </tbody>
      </table>
    </div>

    <div class="VITablePlus_footer" v-if="!noPagination">
      <div class="footer_status">
        <div class="text-muted" role="status" aria-live="polite">{{Math.min( page_ * rowsPerPage_ + 1, indexes.length )}} - {{Math.min( page_ * rowsPerPage_ + rowsPerPage_, rowsCount ? rowsCount : indexes.length )}} {{edw.of}} {{rowsCount ? rowsCount : indexes.length}}</div>
      </div>
      <div class="footer_pagination">
        <div class="pagination_container">
          <ul class="pagination">
            <li :class="[ 'paginate_button', 'page-item', 'previous', { 'disabled': isFirstPage } ]" >
              <a style="cursor: pointer;" class="page-link" @click="goToPrevPage()">{{edw.prev}}</a>
            </li>
            <li v-for="item in paginationItems" :key="item" :class="[ 'paginate_button', 'page-item', { 'active': item == page_ } ]">
              <a style="cursor: pointer;" class="page-link" @click="goToPage( item )">{{ item + 1 }}</a>
            </li>
            <li :class="[ 'paginate_button', 'page-item', 'next', { 'disabled': isLastPage } ]">
              <a style="cursor: pointer;" class="page-link" @click="goToNextPage()">{{edw.next}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global edw */
import VIIcon from './VI-Icon.vue';

// col: {
//   name: String
//   search: String // the name of the parameter to use for search
//   sort: String // the name of the parameter to use for sorting
//   label: String
//   sticky: Boolean // still not supported
//   width: Number || String
//   searchable: Boolean
//   sortable: Boolean
//   clickable: Boolean
// }

export default {
  name: 'VI-Table-Plus',
  components: { VIIcon },
  props: {
    cols: { type: Array, default: function () { return []; } },
    data: { type: Array, default: function () { return []; } },
    filter: { type: String, default: undefined },
    sort: { type: Object, default: undefined },
    rowsPerPage: { type: Number, default: undefined },
    page: { type: Number, default: undefined },
    'no-pagination': { type: Boolean, default: false },
    sortFunc: { type: Function, default: undefined },
    filterFunc: { type: Function, default: undefined },
    pagesCountFunc: { type: Function, default: undefined },
    paginationFunc: { type: Function, default: undefined }
  },
  data () {
    return {
      sort_: this.sort,
      rowHeight: 0,
      tableHeight: 0,
      page_: this.page || 0,
      rowsCount: undefined,
      edw: {
        of: edw('of'),
        prev: edw('prev'),
        next: edw('next')
      }
    };
  },
  computed: {
    indexes: function () {
      let indexes = this.data.map((row, index) => { return index; });

      if (this.filter) {
        const colsSearchKey = this.cols.reduce((acc, col) => { if (col.searchable === undefined || col.searchable === true) acc.push(col.search ? col.search : col.name); return acc; }, []);

        if (this.filterFunc) {
          indexes = this.filterFunc(indexes, colsSearchKey);
        } else {
          indexes = indexes.filter((i) => {
            for (let j = 0; j < colsSearchKey.length; j++) {
              if (this.data[i][colsSearchKey[j]] != null && contains(this.data[i][colsSearchKey[j]], this.filter)) {
                return true;
              }
            }
            return false;
          });
        }
      }

      if (this.sort_ && this.sort_.col !== '' && this.sort_.dir != null && this.sort_.dir !== 0) {
        const sortCol = this.cols.find((col) => { return col.name === this.sort_.col; });
        if (sortCol) {
          if (this.sortFunc) {
            indexes = this.sortFunc(indexes, this.sort_.dir, sortCol.sort ? sortCol.sort : sortCol.name);
          } else {
            const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
            const sortKey = sortCol.sort ? sortCol.sort : sortCol.name;
            const sortDir = this.sort_.dir;

            const sortFunc = (a, b) => {
              if (this.data[a][sortKey] != null && this.data[b][sortKey] != null) {
                if (sortDir > 0) {
                  return collator.compare(String(this.data[a][sortKey]), String(this.data[b][sortKey]));
                } else if (sortDir < 0) {
                  return collator.compare(String(this.data[b][sortKey]), String(this.data[a][sortKey]));
                } else {
                  return a < b ? -1 : 1;
                }
              }
              return this.data[b][sortKey] != null ? 1 : -1;
            };

            indexes = indexes.sort(sortFunc);
          }
        }
      }

      return indexes;
    },
    rowsPerPage_: function () {
      if (this.noPagination) { return this.indexes.length; }
      if (this.rowsPerPage) { return parseInt(this.rowsPerPage); }
      return Math.max(parseInt(this.tableHeight / this.rowHeight), 1);
    },
    pagesCount: function () {
      if (this.noPagination) return 1;
      if (this.pagesCountFunc) return this.pagesCountFunc();
      return this.indexes.length % this.rowsPerPage_ > 0 ? Math.floor(this.indexes.length / this.rowsPerPage_) + 1 : this.indexes.length / this.rowsPerPage_;
    },
    pageIndexes: function () {
      if (this.noPagination) return this.indexes;
      if (this.paginationFunc) return this.paginationFunc();
      return this.indexes.slice(this.page_ * this.rowsPerPage_, this.page_ * this.rowsPerPage_ + this.rowsPerPage_);
    },
    paginationItems: function () {
      const ret = [this.page_];
      const maxItems = 5;

      let i = 1;
      while (ret.length < maxItems && ret.length < this.pagesCount) {
        if (this.page_ + i <= this.pagesCount - 1) { ret.push(this.page_ + i); }
        if (this.page_ - i >= 0) { ret.unshift(this.page_ - i); }

        i++;
      }

      return ret;
    },
    isFirstPage: function () { return this.page_ === 0; },
    isLastPage: function () { return this.page_ === this.pagesCount - 1; }
  },
  watch: {
    sort: function () { this.sort_ = this.sort; },
    page: function () { this.page_ = this.page; },
    indexes: {
      deep: true,
      handler: function () { this.goToPage(this.page_); }
    }
  },
  methods: {
    goToPrevPage () {
      this.page_ = Math.max(this.page_ - 1, 0);
    },
    goToNextPage () {
      this.page_ = Math.min(this.page_ + 1, this.pagesCount - 1);
    },
    goToPage (index) {
      this.page_ = Math.min(Math.max(index, 0), this.pagesCount - 1);
    },
    toggleSort (col) {
      this.sort_ = { col: col, dir: (this.sort_ && this.sort_.col === col) ? (this.sort_.dir + 1 === 2 ? -1 : this.sort_.dir + 1) : 1 };
    },
    calcTableAndRowHeight () {
      if (!this.$refs['row-1'] || !this.$refs.table || !this.$refs.head) { return; }
      this.$refs['row-1'].style.display = 'table-row';
      this.rowHeight = this.$refs['row-1'].offsetHeight;
      this.$refs.table.style.height = '100%';
      this.tableHeight = this.$refs.table.offsetHeight - this.$refs.head.offsetHeight;
      this.$refs.table.style.height = '';
      this.$refs['row-1'].style.display = '';
    },
    cellClick (rowI, col) {
      this.$emit('row-click', { index: rowI, row: this.data[rowI] });
      this.$emit('cell-click', { index: rowI, row: this.data[rowI], col: col, val: this.data[rowI][col] });
    }
  },
  mounted () {
    this.$nextTick(() => { this.calcTableAndRowHeight(); });
  }
};

const contains = function (a, b) { return String(a).toLowerCase().indexOf(b) > -1; };
</script>

<style scoped>
.VITablePlus {
  display: flex;
  flex-flow: column nowrap;
  /* height: 100%; */
}

.VITablePlus_body {
  height: 0;
  flex: 0 1 100%;
  overflow: auto;
  transform: translate3d(0, 0, 0);
}

.VITablePlus_table{
  border-collapse: collapse;
  border-spacing: 0px;
  vertical-align: middle;
  min-width: 100%;
}

.VITablePlus_footer {
  flex: 0 0 auto;
}

.footer_status, .footer_pagination {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.pagination_container {
  flex: 0 0 100%;
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

.footer_pagination .paginate_button {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

table {
  table-layout: fixed;
  transform: translate3d(0, 0, 0);
}

table.nowrap th,
table.nowrap td {
  white-space: nowrap;
}

table thead tr{
  position: sticky;
  top: 0;
  z-index: 1;
}

table thead tr th {
  vertical-align: middle;
  white-space: nowrap;
  background: var(--color-primary-1);
  color: var(--color-primary-text);
  transition: box-shadow .3s ease;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

table thead tr th.sorting{
  cursor: pointer;
  position: relative;
  padding-right: 16px;
  min-height: 14px;
}

table thead tr th .headContentDiv{
  display: inline-block;
  width: 100%;
}

table thead tr th .headStdContent{
  padding: 10px 5px 10px 8px;
}

table thead tr th .headSortDiv{
  display: none;
  float: right;
  position: absolute;
  top: calc(50% - 0.6em);
  right: 3px;
}

table thead tr th.sorting .headSortDiv{
  display: inline-block;
}

table thead tr th .headSortIcon{
  font-size: 1.2em;
}

table thead tr th .headSortIcon.active{
  color: var(--color-highlight);
}

table tbody tr.row-1{
  display: none;
}

table tbody tr{
}

table tbody tr:hover{
  background: var(--color-primary-2);
  color: var(--color-primary-text);
}

table tbody tr td{
  height: 100%;
}

table tbody tr td:hover{
  background: var(--color-primary-3);
  color: var(--color-primary-text);
}

table tbody tr td .cellStdContent{
  padding: 3px 8px;
}

.VITablePlus_footer{
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: var(--color-primary-1);
  color: var(--color-primary-text);
}

ul.pagination > li.paginate_button {
  display: inline-block;
}

li.paginate_button > a{
  padding: 5px;
}

li.paginate_button.active > a{
  color: var(--color-highlight);
}

li.paginate_button.disabled > a{
  opacity: .4;
}
</style>
