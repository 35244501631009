<template>
  <teleport to="body">
    <VI-Scroll-Div @click.stop="$emit( 'close' )" noHScroll noVScroll teleport class="background">
      <VI-Scroll-Div @click.stop noHScroll noVScroll class="modal">
        <div class="container">
          <div class="notesContainer">
            <template v-for="(note, index) in listNotes" :key="note.id + '_' + note.utc">
              <Note :index="index" v-model:changed="note.isChanged" :isNew="note.isNew" :date="note.date" :user="note.user" v-model:content="note.note" @remove="remove"/>
            </template>
          </div>
          <div class="buttonsDiv">
            <VI-Button class="buttons" :label="edw.save" @click="save"/>
            <VI-Button class="buttons" :label="edw.cancel" @click="$emit( 'close' )"/>
          </div>
        </div>
      </VI-Scroll-Div>
    </VI-Scroll-Div>
  </teleport>
</template>

<script>
/* global edw, userConf, HigJS */

import { DateTime } from 'luxon';
import Note from './VI-Note.vue';
import VIButton from '../VI-Button.vue';
import VIScrollDiv from '../VI-Scroll-Div.vue';

export default {
  name: 'Notes-List',
  components: {
    Note,
    VIButton,
    VIScrollDiv
  },
  props: {
    notes: { type: Array }
  },
  emits: ['close', 'removeNote', 'save'],
  data () {
    return {
      userName: userConf.name || '',
      userId: userConf.userId,
      edw: {
        save: edw('save'),
        cancel: edw('cancel')
      }
    };
  },
  computed: {
    listNotes: function () {
      const list = HigJS.obj.clone(this.notes);

      list.forEach((note) => { note.isNew = false; note.isChanged = false; });

      const emptyNote = {
        id: -1,
        isNew: true,
        isChanged: false,
        utc: DateTime.now().toSeconds() + DateTime.now().offset * 60,
        user: this.userName ? this.userName : '',
        userId: this.userId,
        note: ''
      };

      list.unshift(emptyNote);

      list.forEach((note) => { note.date = this.utcToString(note.utc); });

      return list;
    }
  },
  methods: {
    remove (index) {
      this.$emit('removeNote', this.listNotes[index]);
    },
    save () {
      this.listNotes.forEach(element => {
        delete element.isNew;
        delete element.date;
      });

      if (!this.listNotes[0].note) {
        this.listNotes.shift();
      }

      this.$emit('save', this.listNotes);
    },
    utcToString (timeStamp) {
      return HigJS.num.formatDate({ date: true, time: true, utc: timeStamp });
    }
  }
};
</script>

<style scoped>

.background {
  background: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.modal {
  max-height: 90%;
  max-width: 1500px;
  background: var( --color-primary-1 );
  border-radius: 3px;
  display: flex;
  flex-flow: column;
}

.container {
  display: flex;
  flex-flow: column;
  z-index: 20;
}

.notesContainer {
  overflow: auto;
  flex: 1 1 auto;
  padding: 10px;
}

.buttonsDiv {
  flex-flow: row;
  flex: 0 0 auto;
  padding: 10px;
}

.buttons {
  margin: 0 2px;
  min-width: 80px;
}

</style>
