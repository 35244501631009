import Utils from '../../../libs/utils';

/* global HigJS */

// Get info of a single plant
function getPlantsInfo ($store, plantsIds, nodeVarsToLoad) {
  const arrayInput = Array.isArray(plantsIds);
  if (!arrayInput) { plantsIds = [plantsIds]; }
  if (plantsIds.length === 0) { return Promise.resolve(arrayInput ? [] : undefined); }

  const reqs = [];
  let reqsNodeVars = [];
  let node;
  for (let i = 0; i < plantsIds.length; i++) {
    node = $store.state.Nodes.plantInfoLoaded[plantsIds[i]];

    if (node && (!nodeVarsToLoad || nodeVarsToLoad.length === 0)) continue;

    reqsNodeVars = [];

    if (nodeVarsToLoad) {
      nodeVarsToLoad.forEach((nodeVar) => {
        if (node && !node[nodeVar]) reqsNodeVars.push(nodeVar);
      });
    }

    if (!node || reqsNodeVars.length > 0) {
      reqs.push({
        act: 'getAllPlantNodesDescription',
        data: {
          impiantiId: plantsIds[i],
          nodeVarTypes: reqsNodeVars,
          plantStats: true,
          nodeTree: false
        }
      });
    }
  }

  if (reqs.length === 0) {
    const info = [];

    plantsIds.forEach(plantId => {
      info.push($store.state.Nodes.plantInfoLoaded[plantId]);
    });

    return Promise.resolve(arrayInput ? info : info[0]);
  }

  return Utils.ajaxRequest(reqs).then((plantsInfo) => {
    const info = [];
    $store.commit('UPDATE_PLANTS_ADV', plantsInfo.reduce((acc, val) => { if (val[0]) { acc[val[0].plantRefId] = val[0]; } return acc; }, {}));

    plantsIds.forEach(plantId => {
      info.push($store.state.Nodes.plantInfoLoaded[plantId]);
    });

    return arrayInput ? info : info[0];
  }).catch((err) => {
    HigJS.debug.error('[getPlantsInfo] ' + err);
    return arrayInput ? [] : undefined;
  });
}

// get list of all CNS plants
function getPlantsList (nodeVarsToLoad) {
  return Utils.ajaxRequest({
    act: 'getAllPlantNodesDescription',
    data: {
      nodeVarTypes: nodeVarsToLoad || [],
      plantStats: true,
      nodeTree: false
    }
  }).then((plantNodes) => {
    return plantNodes;
  }).catch((err) => {
    HigJS.debug.error('[getPlantsList] ' + err);
    return [];
  });
}

async function getAllUserNodes ($store) {
  // ### Load all nodes if not already done
  if (!$store.state.Nodes.allNodesLoaded) {
    const nodes = await Utils.ajaxRequest({ act: 'VIGetNodes', data: {} }, '/mccCore').catch((err) => { HigJS.debug.error('[getAllUserNodes] ' + err); return undefined; });

    if (nodes != null) {
      $store.commit('UPDATE_NODES', nodes.reduce((acc, el) => { acc[el.id] = el; return acc; }, {}));
      $store.commit('UPDATE_CHILD_NODES_LOADED', nodes.reduce((acc, node) => { acc[node.id] = true; return acc; }, {})); // if all nodes where loaded so all childs of all nodes where loaded too
      $store.commit('LOADED_ALL_NODES');
    }
  }

  return $store.state.Nodes.nodes;
}

const getNodesProms = {};

function getNodesCaching ($store, nodeIds, getChilds) {
  const orderedNodedIds = nodeIds.sort((a, b) => {
    return a - b;
  });
  const joinedNodeIds = orderedNodedIds.join('-') + (getChilds ? '-childs' : '');

  if (!getNodesProms[joinedNodeIds]) {
    getNodesProms[joinedNodeIds] = getNodes($store, nodeIds, getChilds).finally(() => {
      delete getNodesProms[joinedNodeIds];
    });
  }
  return getNodesProms[joinedNodeIds];
}

async function getNodes ($store, nodesIds, getChilds) {
  // ### Load all nodes that are not in the store
  if (getChilds) {
    // if the childs of a requested parent have alerady been loaded do not request them again
    const parentsNodesIds = nodesIds.filter((nodeId) => { return !$store.state.Nodes.childNodesLoaded[nodeId]; });

    if (parentsNodesIds.length) {
      const nodes = await Utils.ajaxRequest({ act: 'VIGetNodes', data: { nodesIds: parentsNodesIds, getChilds: true } }, '/mccCore').catch((err) => { HigJS.debug.error('[getNodes]' + err); return undefined; });

      if (nodes != null) {
        $store.commit('UPDATE_CHILD_NODES_LOADED', nodes.reduce((acc, node) => { acc[node.id] = true; return acc; }, {})); // all the childs of the childs nodes where loaded too
        $store.commit('UPDATE_NODES', nodes.reduce((acc, el) => { acc[el.id] = el; return acc; }, {}));
      }
    }
  } else {
    const nodesIdsToLoad = nodesIds.filter((nodeId) => { return $store.state.Nodes.nodes[nodeId] == null; });

    if (nodesIdsToLoad.length > 0) {
      const nodes = await Utils.ajaxRequest({ act: 'VIGetNodes', data: { nodesIds: nodesIdsToLoad, getChilds: false } }, '/mccCore').catch((err) => { HigJS.debug.error('[getNodes]' + err); return undefined; });

      if (nodes != null) {
        $store.commit('UPDATE_NODES', nodes.reduce((acc, el) => { acc[el.id] = el; return acc; }, {}));
      }
    }
  }

  // ### Get requested nodes from store
  const resNodes = {};
  nodesIds.forEach((nodeId) => {
    if ($store.state.Nodes.nodes[nodeId]) {
      resNodes[nodeId] = $store.state.Nodes.nodes[nodeId];
    }
  });

  if (getChilds) {
    nodesIds.forEach((parentId) => {
      if ($store.state.Nodes.nodes[parentId]) {
        Object.keys($store.state.Nodes.nodes).filter((nodeId) => { // get all descendants
          if ($store.state.Nodes.nodes[nodeId].path_string &&
                        $store.state.Nodes.nodes[nodeId].path_string.split('.').indexOf(String(parentId)) > -1) {
            return true;
          }
          return false;
        }).forEach((nodeId) => {
          resNodes[nodeId] = $store.state.Nodes.nodes[nodeId];
        });
      }
    });
  }

  return resNodes;
}

const getNodesFullProms = {};

function getNodesFullCaching ($store, nodeIds) {
  const orderedNodedIds = nodeIds.sort((a, b) => {
    return a - b;
  });
  const joinedNodeIds = orderedNodedIds.join('-');

  if (!getNodesFullProms[joinedNodeIds]) {
    getNodesFullProms[joinedNodeIds] = getNodesFull($store, nodeIds).finally(() => {
      delete getNodesFullProms[joinedNodeIds];
    });
  }

  return getNodesFullProms[joinedNodeIds];
}

async function getNodesFull ($store, nodesIds) {
  const nodesIdsToLoadFull = nodesIds.filter((nodeId) => { return !$store.state.Nodes.fullNodesLoaded[nodeId]; });

  if (nodesIdsToLoadFull.length > 0) {
    const nodes = await Utils.ajaxRequest({
      act: 'VIGetNodes',
      data: {
        nodesIds: nodesIdsToLoadFull,
        getChilds: false,
        getParameters: true,
        getVariables: true,
        getStructures: true
      }
    }, '/mccCore').catch((err) => { HigJS.debug.error('[getNodesFull] ' + err); return undefined; });

    if (nodes != null) {
      $store.commit('UPDATE_FULL_NODES_LOADED', nodesIdsToLoadFull.reduce((acc, nodeId) => { acc[nodeId] = true; return acc; }, {}));
      $store.commit('UPDATE_NODES', nodes.reduce((acc, el) => { acc[el.id] = el; return acc; }, {}));
    }
  }

  const resNodes = {};
  nodesIds.forEach((nodeId) => {
    if ($store.state.Nodes.nodes[nodeId]) {
      resNodes[nodeId] = $store.state.Nodes.nodes[nodeId];
    }
  });
  return resNodes;
}

const getNodesPartiallyProms = {};

function getNodesPartiallyCaching ($store, nodeIds, opts) {
  const orderedNodedIds = nodeIds.sort((a, b) => {
    return a - b;
  });
  const joinedNodeIds = orderedNodedIds.join('-');

  if (!getNodesPartiallyProms[joinedNodeIds]) {
    getNodesPartiallyProms[joinedNodeIds] = getNodesPartially($store, nodeIds, opts).finally(() => {
      delete getNodesPartiallyProms[joinedNodeIds];
    });
  }

  return getNodesPartiallyProms[joinedNodeIds];
}

async function getNodesPartially ($store, nodesIds, opts) {
  const zip = true;
  let nodes = await Utils.ajaxRequest({
    act: 'VIGetNodes',
    data: {
      zip: zip,
      nodesIds: nodesIds,
      getChilds: false,
      getParameters: opts.parameters,
      getVariables: opts.variables,
      getStructures: opts.structures
    }
  }, '/mccCore', false).catch((err) => { HigJS.debug.error('[getNodesPartially] ' + err); return undefined; });

  if (zip && nodes._zipped === true) {
    const jz = new HigJS.ZipJson();
    nodes = jz.unZip(nodes);
  }

  if (nodes != null) {
    $store.commit('UPDATE_NODES_ADV', nodes.reduce((acc, el) => { acc[el.id] = el; return acc; }, {}));
  }

  const resNodes = {};
  nodesIds.forEach((nodeId) => {
    if ($store.state.Nodes.nodes[nodeId]) {
      resNodes[nodeId] = $store.state.Nodes.nodes[nodeId];
    }
  });
  return resNodes;
}

async function getAllNodeTypes ($store) {
  if (!$store.state.Nodes.types || Object.keys($store.state.Nodes.types).length === 0) {
    await Utils.ajaxRequest({
      act: 'getNodeTypes',
      data: { }
    }).then((data) => {
      const nodeTypesById = {};
      for (let i = 0; i < data.length; i++) {
        nodeTypesById[data[i].id] = data[i].name;
      }
      $store.commit('SET_NODE_TYPES', nodeTypesById);
    }).catch((err) => {
      HigJS.debug.error('[getAllNodeTypes] ' + err);
    });
  }

  return $store.state.Nodes.types;
}

async function getAllVarPeriods ($store) {
  if (!Array.isArray($store.state.Nodes.varPeriods) || $store.state.Nodes.varPeriods.length === 0) {
    await Utils.ajaxRequest({
      act: 'getNodeVarPeriods',
      data: {}
    }).then((data) => {
      $store.commit('SET_VAR_PERIODS', data);
    }).catch((err) => {
      HigJS.debug.error('[getAllVarPeriods] ' + err);
    });
  }

  return $store.state.Nodes.varPeriods;
}

async function getAllVarTypes ($store) {
  if (!Array.isArray($store.state.Nodes.varTypes) || $store.state.Nodes.varTypes.length === 0) {
    await Utils.ajaxRequest({
      act: 'getVarType',
      data: {}
    }).then((data) => {
      $store.commit('SET_VAR_TYPES', data);
    }).catch((err) => {
      HigJS.debug.error('[getAllVarTypes] ' + err);
    });
  }

  return $store.state.Nodes.varTypes;
}

export default {
  getPlantInfo: getPlantsInfo,
  getPlantsInfo,
  getPlantsList,
  getAllUserNodes,
  getNodesCaching,
  getNodes,
  getNodesFullCaching,
  getNodesFull,
  getNodesPartiallyCaching,
  getAllNodeTypes,
  getAllVarPeriods,
  getAllVarTypes
};
