<template>
  <div class="tabs-container" ref="container" :class="{ standard: standardStyle, legacy: legacyStyle, center: centered }">
    <div class="tab" v-for="(link, index) in links" :key="index">
      <router-link class="tab-link" :to="link.to">{{link.label}}</router-link>
    </div>
    <div class="shadow-div" :style="shadowStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'VI-Tabs',
  props: {
    links: { type: Array, default: () => { return []; } },
    legacyStyle: { type: Boolean, default: false },
    centered: { type: Boolean, default: false }
  },
  data () {
    return {
      standardStyle: !this.legacyStyle,
      sizes: [],
      shadowStyle: {
        width: '100%',
        transform: 'translateX(0)'
      }
    };
  },
  methods: {
    update () {
      if (!this.$refs.container) { return; }
      const oldSelectedTab = this.$refs.container.getElementsByClassName('tab selected');
      const selectedLink = this.$refs.container.getElementsByClassName('router-link-active');

      if (oldSelectedTab.length > 0) {
        oldSelectedTab[0].classList.remove('selected');
      }

      if (selectedLink.length > 0) {
        const newSelectedTab = selectedLink[0] && selectedLink[0].parentElement;
        newSelectedTab.classList.add('selected');

        this.shadowStyle = {
          width: newSelectedTab.clientWidth + 'px',
          transform: 'translateX(' + newSelectedTab.offsetLeft + 'px)'
        };
      } else {
        this.shadowStyle = {
          width: '100%',
          transform: 'translateX(0)'
        };
      }
    }
  },
  watch: {
    links: {
      handler: function () {
        this.$nextTick(() => {
          this.update();
        });
      }
    },
    $route: {
      immediate: true,
      handler: function () {
        this.$nextTick(() => {
          this.update();
        });
      }
    }
  }
};
</script>

<style scoped>
/* standard style */
.tabs-container.standard{
  position: relative;
  width: 100%;
  height: 40px;
  /* border-top: 1px solid var(--color-primary); */
  background: var(--color-primary-1);

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.tabs-container.standard.center{
  justify-content: center;
}

.tabs-container.standard .shadow-div{
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 2px;
  background: var(--color-highlight);
  border-radius: 1px;
  pointer-events: none;

  transition: transform .3s ease, width .3s ease;
}

.tabs-container.standard .tab{
  color: var(--color-primary-text);
  margin-left: 10px;
  height: 100%;
  min-width: 100px;
  cursor: pointer;
  text-align: center;

  transition: color .3s ease;
}

.tabs-container.standard .tab-link{
  text-decoration: none;
  height: 100%;
  padding: 12px 10px;
  color: inherit;
  display: block;
}

.tabs-container.standard .tab.selected{
  cursor: initial;
}

.tabs-container.standard .tab:hover{
  color: var(--color-highlight);
}

/* legacy style */
.tabs-container.legacy{
  position: relative;
  width: 100%;
  background: #FFF0;
  height: 33px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.tabs-container.legacy.center{
  justify-content: center;
}

.tabs-container.legacy .shadow-div{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  box-shadow: 0px -3px 0px 0px inset #000;
}

.tabs-container.legacy .tab{
  background: #FFF0;
  color: #000;
  border: none;
  height: 28px;
  min-height: 24px;
  margin-left: 5px;
  cursor: pointer;
  z-index: 0;
}

.tabs-container.legacy .tab-link{
  text-decoration: none;
  padding: 4px 9px;
  font-family: Arial;
  font-size: 14px;
  color: inherit;
  display: block;
}

.tabs-container.legacy .tab.selected{
  background: #000;
  color: #FFF;
  cursor: default;
}

.tabs-container.legacy .tab.selected .tab-link{
  font-weight: bold;
}
</style>
