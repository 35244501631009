<template>
  <div :class="[ 'CNSTreewalker', { 'loading': loading }]" ref="container"></div>
  <VI-Placeholder :loading="loading" :component="containerRef" :color="$colors['--color-highlight']"/>
</template>

<script>
/* global HigJS, treeWalker */

import VIPlaceholder from './VI-Placeholder.vue';

export default {
  name: 'CNS-Treewalker',
  inject: ['$colors'],
  components: {
    VIPlaceholder
  },
  props: {
    conf: { type: Object, default: function () { return {}; } },
    loading: { type: Boolean, default: false },
    getSelection: { type: Boolean, default: false },
    toggleSections: { type: Object, default: function () { return {}; } },
    getSectionStatus: { type: [String, Boolean], default: false }
  },
  emits: ['onLoadNodes', 'onLoadVars', 'nodeClick', 'varClick', 'selectNode', 'selection'],
  data () {
    return {
      treewalker: undefined,
      containerRef: undefined
    };
  },
  watch: {
    getSelection: function () {
      if (this.getSelection) this.emitSelection();
    },
    toggleSections: function () {
      if (!this.treewalker) return;
      this.treewalker.toggleSections(this.toggleSections);
    },
    getSectionStatus: function () {
      if (!this.treewalker) return;
      if (typeof this.getSectionStatus === 'string') {
        this.$emit('sectionStatus', this.treewalker.getSectionsStatus(this.getSectionStatus));
      } else if (this.getSectionStatus) {
        this.$emit('sectionStatus', this.treewalker.getSectionsStatus());
      }
    }
  },
  methods: {
    mergeConfs () {
      return HigJS.obj.extendAdv(this.defaultConf(), this.conf);
    },
    defaultConf () {
      return {
        whatToRequest: { children: true },
        includeOrphans: false,
        appendTo: this.containerRef,
        onLoadNodes: this.emitOnLoadNodes,
        onLoadVars: this.emitOnLoadVars,
        onNodeClick: this.emitOnNodeClick,
        onVarClick: this.emitOnVarClick,
        onSelect: this.emitOnSelect
      };
    },
    emitOnLoadNodes (...args) {
      this.$emit('onLoadNodes', args);
    },
    emitOnLoadVars (...args) {
      this.$emit('onLoadVars', args);
    },
    emitOnNodeClick (...args) {
      this.$emit('nodeClick', args);
    },
    emitOnVarClick (...args) {
      this.$emit('varClick', args);
    },
    emitOnSelect (...args) {
      this.$emit('selectNode', args);
    },
    emitSelection () {
      if (!this.treewalker) return;
      const resp = this.treewalker.getSelection();
      this.$emit('selection', resp);
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.containerRef = this.$refs.container;
      // eslint-disable-next-line new-cap
      this.treewalker = new treeWalker(this.mergeConfs());
    });
  }
};
</script>

<style scoped>
.CNSTreewalker{
  background-color: var(--color-primary);
  padding: 10px;
}

.CNSTreewalker.loading{
  opacity: 0;
}
</style>

<style>

.hig_tree_viewer{
  color: var(--color-primary-text);
}

.hig_tree_viewer .tagline .arrow_opener {
  background-image: url('../assets/arrow_down.svg');
}

.hig_tree_viewer li:last-of-type > .tagline::after,
.hig_tree_viewer li::before {
  border-left: 1px solid #159de74d;
}

.hig_tree_viewer .tagline::after {
  border-bottom: 1px solid #159de74d;
}

.hig_tree_viewer ul.root > li:not(:last-of-type) {
  border-bottom: 1px solid #159de780;
}

</style>
