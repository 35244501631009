<template>
  <div :class="[ 'VIToggle', { 'readonly': readonly }]" @click='toggleClick'>
    <div class="toggleBg"></div>
    <div :class="[ 'toggleSlider', { 'off': !curValue, 'on': curValue }]"></div>
  </div>
</template>

<script>
export default {
  name: 'VI-Toggle',
  props: {
    value: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      curValue: this.value
    };
  },
  watch: {
    value: function () { this.curValue = this.value; }
  },
  methods: {
    toggleClick () {
      if (this.readonly) { return; }
      this.curValue = !this.curValue;
      this.$emit('update:value', this.curValue);
    }
  }
};
</script>

<style scoped>
.VIToggle{
  --bg-color: var(--color-secondary-1);
  --slider-color: var(--color-highlight);

  position: relative;
  padding: 7px;
}

.VIToggle.readonly{
  opacity: .5;
}

.VIToggle:not(.readonly){
  cursor: pointer;
}

.VIToggle .toggleBg{
  width: 30px;
  height: 15px;
  border-radius: 10px;
  background: var(--bg-color);
}

.VIToggle .toggleSlider{
  position: absolute;
  top: 8px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--slider-color);
  transition: filter .3s ease, left .3s ease, transform .2s ease;
}

.VIToggle .toggleSlider.off{
  filter: grayscale();
  left: 8px;
}

.VIToggle .toggleSlider.on{
  left: 22px;
}

.VIToggle:not(.readonly):hover .toggleSlider.off{ transform: translateX(1px); }
.VIToggle:not(.readonly):hover .toggleSlider.on{ transform: translateX(-1px); }
</style>
