<template>
  <div v-if="loading" :class="[ 'VIPlaceholder', { 'round': round } ]" :style="loaderStyle" ref="placeholder"></div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

export default {
  name: 'VI-Placeholder',
  props: {
    loading: { type: Boolean, default: false },
    component: { type: Object, default: undefined },
    round: { type: Boolean, default: false },
    color: { type: String, default: '' }
  },
  data () {
    return {
      loaderStyle: { display: 'none' },
      componentCurDisplay: ''
    };
  },
  watch: {
    component: {
      immediate: true,
      handler: function () {
        this.$nextTick(() => {
          if (!this.component || !this.component.getBoundingClientRect) { return; }
          const componentSize = this.component.getBoundingClientRect();

          const componentStyle = getComputedStyle(this.component);

          this.loaderStyle = {
            width: componentSize.width + 'px',
            height: componentSize.height + 'px',
            color: this.color || componentStyle.color,
            display: componentStyle.display,
            margin: componentStyle.margin,
            'margin-top': componentStyle['margin-top'],
            'margin-right': componentStyle['margin-right'],
            'margin-bottom': componentStyle['margin-bottom'],
            'margin-left': componentStyle['margin-left']
          };

          if (this.loading) {
            this.componentCurDisplay = this.component.style.display;
            this.component.style.display = 'none';
          }
        });
      }
    },
    loading: function () {
      if (!this.component) { return; }
      if (this.loading) {
        this.componentCurDisplay = this.component.style.display;
        this.component.style.display = 'none';
      } else {
        if (this.componentCurDisplay) {
          this.component.style.display = this.componentCurDisplay;
        } else {
          this.component.style.removeProperty('display');
        }
      }
    }
  },
  mounted () {}
};
</script>

<style scoped>
.VIPlaceholder{
  flex: 0 0 auto;
  background: currentColor;
  border-radius: 5px;
  animation: pulseOpacity 3s ease-in-out infinite;
  opacity: .6;
}

.VIPlaceholder.round{
  border-radius: 50%;
}

@keyframes pulseOpacity {
  50%{
    opacity: .3;
  }
}
</style>
