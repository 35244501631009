<template>
  <div class="VI-Card">
    <div class="title" v-if="title != ''">{{title}}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VI-Card',
  props: {
    title: { type: String, default: '' }
  }
};
</script>

<style scoped>
.VI-Card{
  background: var(--color-secondary);
  color: var(--color-secondary-text);
  padding: 5px;
  border-radius: 16px;
}

.VI-Card > .title{
  text-align: left;
  padding: 3px 8px;
  font-size: 1.5em;
  color: var(--color-highlight);
  border-bottom: 1px solid var(--color-secondary-1);
  margin-bottom: 3px;
}
</style>
