<template>
  <div class="note">
    <div @click="notifyDeleteNote">
      <VI-Icon v-if="!isNew" class="iconClose" type="times-circle"/>
    </div>
    <div class="container">
      <div class="content">
        <div class="header">
          <VI-Label class="date" :text="date"/>
          <VI-Label class="user" :text="user"/>
        </div>
        <textarea type="textarea" rows="5" cols="50" :placeholder="edw.newNote" class="noteTextArea" v-model="noteContent"/>
      </div>
    </div>
  </div>
</template>

<script>
/* global edw */

import VIIcon from '../VI-Icon.vue';
import VILabel from '../VI-Label.vue';

export default {
  name: 'VI-Note',
  components: {
    VIIcon,
    VILabel
  },
  emits: ['update:content', 'update:changed'],
  props: {
    index: { type: Number, default: 0, required: true },
    isNew: { type: Boolean, default: false },
    date: { type: String, default: '' },
    user: { type: String, default: '' },
    content: { type: String, default: '' },
    changed: { type: Boolean, default: false }
  },
  data () {
    return {
      noteContent: this.content,

      edw: { // TODO change label
        newNote: edw('newNote')
      }
    };
  },
  watch: {
    noteContent: function () {
      this.$emit('update:changed', String(this.content) !== String(this.noteContent));
      this.$emit('update:content', this.noteContent);
    }
  },
  methods: {
    notifyDeleteNote () {
      this.$emit('remove', this.index);
    }
  }
};
</script>

<style scoped>
.note {
  display: block;
  padding: 10px 10px;
  position: relative;
}

.container {
  padding: 10px;
  background-color: var(--color-primary);
}

.iconClose {
  position: absolute;
  top: 0px;
  right: 10px;
  transform: translateX(50%);
  z-index: 1;
  font-size: 1.4em;
  font-weight: bold;
  color: var( --color-error );
  cursor: pointer;
}

.content {
  flex-flow: column;
}

.header {
  display: flex;
  width: 100%;
  flex-flow: row;
}

.date {
  width: 50%;
  text-align: left;
  color: rgb(0, 136, 101);
}

.user {
  width: 50%;
  text-align: right;
  color: rgb(0, 136, 101);;
}

.noteTextArea {
  flex: 0 0 auto;
  resize: none;
}

</style>
