import { DateTime } from 'luxon';

/* global HigJS, edw */

function ms (desc) {
  if (typeof desc !== 'string') { return undefined; }

  const matches = desc.match(/^(\d+(\.\d+)?)([smhdw])$/);
  if (!matches) { return undefined; }

  const mul = matches[1];
  const unit = matches[3];

  switch (unit) {
    case 's': // seconds
      return mul * 1000;
    case 'm': // minutes
      return mul * 60 * 1000;
    case 'h': // hours
      return mul * 60 * 60 * 1000;
    case 'd': // days
      return mul * 24 * 60 * 60 * 1000;
    case 'w': // weeks
      return mul * 7 * 24 * 60 * 60 * 1000;
    default:
      return mul;
  }
}

function s (desc) {
  const millis = ms(desc);
  return millis ? parseInt(millis / 1000) : millis;
}

function interval (desc) {
  if (typeof desc !== 'string' || !desc.match(/^((last|next|curr|prev|succ)(Minute|Hour|Day|Week|Month|Year))|(last|next)(\d+(\.\d+)?)([smhdw])$/)) { console.warn('Wrong format for interval desc: ' + desc); return undefined; }

  const pos = desc.substring(0, 4);
  const int = desc.substring(4).toLowerCase();
  let now = DateTime.utc();
  let millis;

  switch (pos) {
    case 'curr':
      return { start: now.startOf(int).toMillis(), stop: now.endOf(int).toMillis() };
    case 'prev':
      now = now.minus({ [int + 's']: 1 });
      return { start: now.startOf(int).toMillis(), stop: now.endOf(int).toMillis() };
    case 'succ':
      now = now.plus({ [int + 's']: 1 });
      return { start: now.startOf(int).toMillis(), stop: now.endOf(int).toMillis() };
    case 'last':
      millis = ms(int);
      if (millis == null) { return { start: now.minus({ [int + 's']: 1 }).toMillis(), stop: now.toMillis() }; } else { return { start: now.minus({ milliseconds: millis }).toMillis(), stop: now.toMillis() }; }
    case 'next':
      millis = ms(int);
      if (millis == null) { return { start: now.toMillis(), stop: now.plus({ [int + 's']: 1 }).toMillis() }; } else { return { start: now.toMillis(), stop: now.plus({ milliseconds: millis }).toMillis() }; }
  }
}

Object.defineProperty(interval, 'ms', {
  value: function (desc) {
    return this(desc);
  }
});

Object.defineProperty(interval, 's', {
  value: function (desc) {
    const msInt = this(desc);
    return { start: parseInt(msInt.start / 1000), stop: parseInt(msInt.stop / 1000) };
  }
});

/**
 * For every element in the source copy it to target
 *
 * @param {object} target
 * @param {object} source
 * @return {object} the target element updated
 *
 * @example
 *
 * var obj1 = { colors: { color1: "#AF1200", color2: "#1200AF" }, numbers: { one: 1, two: 2 } }
 * var obj2 = { colors: { color2: "#000000", color3: "#00AF12" }, shapes: { triangle: "3", square: "4" } }
 *
 * simpleMergeObjects(obj1, obj2) // => { colors: { color2: "#000000", color3: "#00AF12" }, shapes: { triangle: "3", square: "4" }, numbers: { one: 1, two: 2 } }
 *
 */
function simpleMergeObjects (target, source) {
  if (typeof target === 'undefined') {
    return undefined;
  }
  if (typeof source === 'undefined') {
    return target;
  }

  for (const el in source) {
    target[el] = source[el];
  }

  return target;
}

/**
 * Similar to {@link simpleExtendObj} but go deeper into all the objects
 *
 * @param {object} target
 * @param {object} source
 * @return {object} the target element updated
 *
 * @example
 *
 * var obj1 = { colors: { color1: "#AF1200", color2: "#1200AF" } }
 * var obj2 = { colors: { color2: "#000000", color3: "#00AF12" }, shapes: { triangle: "3", square: "4" } }
 *
 * advExtendObj(obj1, obj2) // => { colors: { color1: "#AF1200", color2: "#1200AF", color3: "#00AF12" }, shapes: { triangle: "3", square: "4" } }
 *
 */
function advExtendObj (target, source) {
  if (typeof target === 'undefined') {
    return source;
  }
  if (typeof source === 'undefined') {
    return target;
  }

  if (typeof target !== 'object') {
    return target;
  }

  for (const el in source) {
    if (typeof target[el] === 'undefined') {
      target[el] = source[el];
    } else {
      target[el] = advExtendObj(target[el], source[el]);
    }
  }

  return target;
}

/**
 * Simplify the call to node
 *
 * @param {Array} reqs - requests object, can be an object or an array, the return value will follow that ( obj => obj, array => array )
 * @param {string} reqs[].act - Act to use with the ajax request
 * @param {string} [reqs[].idReq=reqs[].act] - Request id to use with the ajax request
 * @param {Object} reqs[].data - Data to send to the cgi
 * @param {String} [action="/node"] - The cgi to call
 *
 * @returns {Promise}
 */
function ajaxRequest (reqs, action, crypt = true) {
  const reqsIsArray = Array.isArray(reqs);
  reqs = reqsIsArray ? reqs : [reqs];

  return new Promise((resolve, reject) => {
    reqs.forEach((req) => {
      if (!req.act) {
        reject(edw('errorNoActSpecified'));
        return;
      }

      if (!req.data) {
        reject(edw('errorNoDataSpecified'));
      }
    });

    reqs = reqs.map((req) => { req.idReq = req.idReq ? req.idReq : req.act; return req; });

    HigJS.ajax.request({
      reqs: reqs,
      ids: window.userConf.ids,
      url: action || '/node',
      nodeJs: crypt && !(action && action.match(/\.(php|PHP)$/)),
      onSuccess: function (msg) {
        if (!HigJS.ajax.checkResponse(msg).ok) {
          reject(HigJS.ajax.checkResponse(msg).errMsg);
          return 0;
        }

        const results = [];
        for (let i = 0; i < msg.DATI.length; i++) {
          results.push(msg.DATI[i].DATI);
        }

        resolve(reqsIsArray ? results : results[0]);
      },
      onError: reject
    });
  });
}

/**
 * Simplify the call to node file uploading
 *
 * @param {Object} req - request object
 * @param {string} req.act - Act to use with the ajax request
 * @param {string} [req.idReq=req.act] - Request id to use with the ajax request
 * @param {Object} req.data - Data to send to the cgi
 * @param {Object|Array} files - The file(s) to upload
 * @param {Object} [callbacks] - An object that contains the callbacks
 * @param {Function} [callbacks.onProgress] - Function called every time there is some progress on the upload
 * @param {Function} [callbacks.onComplete] - Function called when the upload is complete
 * @param {String} [action="/node"] - The cgi to call
 *
 * @returns {Promise}
 */
function ajaxUpload (req, files, callbacks, action, crypt = true) {
  // callbacks is optional, so if it's a string treat it like it's the action parameter
  if (typeof callbacks === 'string') {
    action = callbacks;
    callbacks = undefined;
  }

  return new Promise((resolve, reject) => {
    if (req.act == null) {
      reject(edw('errorNoActSpecified'));
      return;
    }

    if (req.data == null) {
      reject(edw('errorNoDataSpecified'));
      return;
    }

    if (!files || (Array.isArray(files) && files.length === 0)) {
      reject(edw('errorNoFilesToUpload'));
      return;
    }
    files = Array.isArray(files) ? files : [files];

    HigJS.ajax.uploadFiles({
      reqs: [req],
      ids: window.userConf.ids,
      files: files,
      url: action || '/node',
      nodeJs: crypt && !(action && action.match(/\.(php|PHP)$/)),
      onUploadProgress: function (e) { callbacks?.onProgress?.((e?.loaded ?? 1) / (e?.total ?? 1) * 100); },
      onUploadComplete: function () { callbacks?.onComplete?.(); },
      onUploadError: function (err) { reject(err); },
      onSuccess: function (msg) {
        if (!HigJS.ajax.checkResponse(msg).ok) {
          reject(HigJS.ajax.checkResponse(msg).errMsg);
          return 0;
        }

        resolve(msg.DATI[0].DATI);
      },
      onError: function (err) { reject(err); }
    });
  });
}

function compileString (str, data) {
  let prevVar = 0;
  let nextVar = str.indexOf('{{', prevVar);
  let compiledStr = '';
  while (nextVar > -1) {
    compiledStr += str.substring(prevVar, nextVar);

    const endVar = str.indexOf('}}', nextVar);
    const varName = str.substring(nextVar + 2, endVar);

    if (data[varName]) {
      compiledStr += typeof data[varName] === 'object' ? JSON.stringify(data[varName], undefined, '    ') : data[varName];
    }

    prevVar = endVar + 2;
    nextVar = str.indexOf('{{', prevVar);
  }
  compiledStr += str.substring(prevVar);

  return compiledStr;
}

function round (num, decimals = 2, asString = false) {
  if (!isNum(num)) { return num; }
  const ret = Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return asString ? ret.toFixed(decimals) : ret;
}

function floor (num, decimals = 2, asString = false) {
  if (!isNum(num)) { return num; }
  const ret = Math.floor(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return asString ? ret.toFixed(decimals) : ret;
}

function ceil (num, decimals = 2, asString = false) {
  if (!isNum(num)) { return num; }
  const ret = Math.ceil(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return asString ? ret.toFixed(decimals) : ret;
}

/**
 *
 * @param {*} tickFunction: Must accept a parameter that's the finish callback that must be called when the function finishes it's execution
 * @param {*} period
 */
function Daemon (tickFunction, period) {
  let daemonRunning = false; let tickRunning = false; let daemonTimeout; let tickPromise;

  const tick = function (forceExecution) {
    if ((!forceExecution && !daemonRunning) || tickRunning) { return tickPromise || Promise.resolve(); }

    const tickStart = Date.now();
    tickRunning = true;

    tickPromise = new Promise((resolve) => {
      const tickRes = tickFunction(resolve);
      if (tickRes && typeof tickRes.then === 'function') {
        tickRes.then(resolve);
      }
    })
      .catch((err) => { console.error(err); })
      .finally(() => {
        tickRunning = false;
        tickPromise = undefined;

        if (daemonRunning) { // queue the next execution only if the daemon is running
          daemonTimeout = setTimeout(() => { tick(); }, Math.max(period - (Date.now() - tickStart), 1000));
        }
      });

    return tickPromise;
  };

  const start = function () {
    if (daemonRunning) { return tickPromise || Promise.resolve(); } // do not start the daemon twice
    daemonRunning = true;
    return tick();
  };

  const stop = function () {
    daemonRunning = false;
    clearTimeout(daemonTimeout);
  };

  const tickNow = function () {
    clearTimeout(daemonTimeout); // try clearing the timeout
    return tick(true); // try to execute the tick forcing it's execution if the daemon is not running, if already running it will not be executed but the task will be filled anyway
  };

  Object.defineProperty(this, 'start', { value: start });
  Object.defineProperty(this, 'stop', { value: stop });
  Object.defineProperty(this, 'tickNow', { value: tickNow });
}

/**
 * @param {String} period       the old var period that needs to be converted with the new declaration
 * @param {Array} varPeriods    the array of varPeriods available for that server. Can be created with the $getVarPeriods() method.
 *
 * @return {String}             return the converted period if it was possible to convert it. Otheway return the original period.
 *
 * @example
 *
 * let min = convertVarPeriod('min', [{id: 1, name: "15m", ... }, {id: 2, name: "1hour", ... }, ...]); // => min = '15m';
 */
function convertVarPeriod (period, varPeriods) {
  if (!Array.isArray(varPeriods)) return period;
  let converted = typeof (period) === 'string' ? period : String(period);

  let list = [];
  if (varPeriods.every(el => { return typeof (el) === 'string'; })) {
    list = varPeriods;
  } else if (varPeriods.every(el => { return typeof (el) === 'object'; })) {
    varPeriods.forEach(period => {
      list.push(period.name);
    });
  } else {
    return period;
  }

  switch (period) {
    case 'day':
      converted = '1day';
      break;
    case 'hour':
      converted = '1hour';
      break;
    case 'month':
      converted = '1month';
      break;
    case 'year':
      converted = '1year';
      break;
    case 'min':
      if (list.includes('5m')) { converted = '5m'; break; }
      if (list.includes('10m')) { converted = '10m'; break; }
      if (list.includes('15m')) { converted = '15m'; break; }
      if (list.includes('1hour')) { converted = '1hour'; break; }
      if (list.includes('1day')) { converted = '1day'; break; }
      if (list.includes('1month')) { converted = '1month'; break; }
      converted = '1year';
      break;
  }

  return converted;
}

/**
 *  Function that evaluates if a utc is old by confronting it with the current time.
 *
 * @param {Number} utc          the utc to be controlled. Must be timestamp in the zone of the gwc. Below is an example of a function to convert the utc received from the cgi back to the original value.
 * @param {Number} multiplier   the multiplier used to multiply the 'period.sampleTime' and evaluate if the utc is old.
 * @param {Object} period       object corresponding to one of the elements of the Array received from $getVarPeriods().Should be the one corresponding to the period of the variable.
 *
 * @return {Boolean}            return true if the utc is old, otherway false.
 *
 * example function to change the utc back to the original value using Luxon:
 * function utcCorrected(utc, zone) {
 *      let correct = DateTime.fromMillis(utc).toUTC().toFormat("yyyy LLL dd hh mm ss");
 *      correct = DateTime.fromFormat(correct, "yyyy LLL dd hh mm ss", {zone: zone}).toMillis();
 *
 *      return correct;
 * }
 *
 * @example
 *
 * let old = checkUtcOld(1621434844000, {id: 1, name: "15m", description: "15 minutes", label: "varPer15min", table: "data15Min", conf: 10, sampleTime: "900"}, 5); // => old = false;
 */

function checkUtcOld (utc, period, multiplier = 3) {
  if (!(isNum(utc) &&
        isNum(multiplier) &&
        period &&
        period.sampleTime)) {
    console.error('Function \'checkOld()\' incorrectly setup.');
    return false;
  }

  utc = parseFloat(utc);
  multiplier = parseFloat(multiplier);
  let date, sampleTime;
  sampleTime = period.sampleTime;

  if (isNum(sampleTime)) {
    date = DateTime.utc().toMillis();
    date = Math.floor(date / 60 / 1000);
    sampleTime = Math.floor(sampleTime / 60);
    while (date % (+sampleTime) !== 0) {
      date--;
    }
    return date - Math.floor(utc / 60 / 1000) > multiplier * sampleTime;
  } else {
    date = DateTime.utc().startOf(sampleTime);
    switch (sampleTime) {
      case 'day':
        return utc < date.minus({ days: multiplier }).toMillis();
      case 'month':
        return utc < date.minus({ months: multiplier }).toMillis();
      case 'year':
        return utc < date.minus({ years: multiplier }).toMillis();
    }
  }
  return false;
}

function isNum (num) {
  return !isNaN(parseFloat(num)) && // prevent empty for being detected as numbers since ( + "" === 0 )
    !isNaN(+num) && // prevent strings like "1.32a" for being detected since they pass the previous control
    isFinite(+num); // check finiteness
}

function validateNum (value, settings, nodeId) {
  nodeId = nodeId || 'default';

  if (!HigJS.num.isNum(value)) { return value; }
  if (settings == null) { return HigJS.num.format(value); }

  if (settings.max != null) {
    const max = HigJS.num.isNum(settings.max[nodeId]) ? settings.max[nodeId] : settings.max.default;
    if (HigJS.num.isNum(max) && parseFloat(value) > parseFloat(max)) {
      return HigJS.num.format(null);
    }
  }

  if (settings.min != null) {
    const min = HigJS.num.isNum(settings.min[nodeId]) ? settings.min[nodeId] : settings.min.default;
    if (HigJS.num.isNum(min) && parseFloat(value) < parseFloat(min)) {
      return HigJS.num.format(null);
    }
  }

  if (settings.map != null) {
    const mapVal = settings.map[nodeId] ? nodeId : 'default';
    if (settings.map[mapVal][value]) {
      return settings.map[mapVal][value];
    } else if (settings.map[mapVal].default) {
      return settings.map[mapVal].default;
    }
  }

  return HigJS.num.format(value);
}

function contains (a, b) { return String(a).toLowerCase().indexOf(b.toLowerCase()) > -1; };

export default {
  ms,
  s,
  interval,
  simpleMergeObjects,
  ajaxRequest,
  ajaxUpload,
  advExtendObj,
  compileString,
  round,
  floor,
  ceil,
  Daemon,
  convertVarPeriod,
  checkUtcOld,
  isNum,
  validateNum,
  contains
};
