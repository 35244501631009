<template>
  <div :class="['VIDatepicker', {'readonly': readonly}]">
    <div class="label" v-if="label != ''" v-on:click="focusInput">{{label}}</div>
    <input class="input" ref="input" type="text" :value="formattedValue" readonly/>
  </div>
</template>

<script>
/* global $,moment,HigJS, edw */

export default {
  name: 'VI-Datepicker',
  props: {
    label: { type: [String, Number], default: '' },
    value: { type: Number, default: undefined },
    format: { type: [String, Function], default: undefined },
    opens: { type: String, default: 'center' },
    drops: { type: String, default: 'down' },
    time: { type: Boolean, default: false },
    timeIncrement: { type: Number, default: 1 },
    time12: { type: Boolean, default: false },
    dropdowns: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    seconds: { type: Boolean, default: false },
    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined },
    locale: { type: Object, default: undefined }
  },
  data () {
    return {
      dateRangePicker: undefined,
      rawValue: undefined,
      initTo: undefined
    };
  },
  computed: {
    formattedValue: function () {
      if (this.rawValue == null) { return ''; }

      if (typeof this.format === 'function') {
        return this.format(this.seconds ? parseInt(this.rawValue / 1000) : this.rawValue);
      } else if (typeof this.format === 'string') {
        return moment.utc(this.rawValue).format(this.format);
      } else {
        return HigJS.num.formatDate({ utc: this.rawValue / 1000, date: true, time: this.time });
      }
    }
  },
  watch: {
    value: function () { this.updateRawValue(); },
    opens: function () { this.init(); },
    drops: function () { this.init(); },
    timeIncrement: function () { this.init(); },
    time: function () { this.init(); },
    time12: function () { this.init(); },
    dropdowns: function () { this.init(); },
    min: function () { this.init(); },
    max: function () { this.init(); }
  },
  methods: {
    init () {
      if (this.readonly || !this.$refs.input) {
        if (this.dateRangePicker) { this.dateRangePicker.remove(); }
        return;
      }

      clearTimeout(this.initTo);
      this.initTo = setTimeout(() => {
        if (this.dateRangePicker) { this.dateRangePicker.remove(); }

        $(this.$refs.input).daterangepicker({ // I REALLY HATE TO PUT jQuery HERE BUT IS THE SAFEST/FASTEST WAY TO DO IT, WILL CHANGE THAT IN FUTURE
          opens: this.opens || 'center',
          drops: this.drops || 'down',
          singleDatePicker: true,
          timePicker: this.time,
          timePickerIncrement: this.timeIncrement,
          timePicker24Hour: !this.time12,
          autoUpdateInput: false,
          showDropdowns: this.dropdowns,
          minDate: this.min != null ? moment(this.seconds ? this.min * 1000 : this.min).startOf('day') : undefined,
          maxDate: this.max != null ? moment(this.seconds ? this.max * 1000 : this.max).endOf('day') : undefined,
          locale: HigJS.obj.extendAdv({
            applyLabel: edw('apply'),
            cancelLabel: edw('cancel'),
            fromLabel: edw('from'),
            toLabel: edw('to'),
            customRangeLabel: edw('custom'),
            weekLabel: '',
            daysOfWeek: [
              edw('sun'),
              edw('mon'),
              edw('tue'),
              edw('wed'),
              edw('thu'),
              edw('fri'),
              edw('sat')
            ],
            monthNames: [
              edw('january'),
              edw('february'),
              edw('march'),
              edw('april'),
              edw('may'),
              edw('june'),
              edw('july'),
              edw('august'),
              edw('september'),
              edw('october'),
              edw('november'),
              edw('december')
            ],
            firstDay: 1
          }, this.locale)
        }, (start) => { /* Changes in the rangepicker are only applied on apply */ });
        $('.daterangepicker:last').addClass('VIDatepickerPopup');

        $(this.$refs.input).on('apply.daterangepicker', (ev, picker) => {
          this.dateSelected(moment(picker.startDate).add(picker.startDate.utcOffset(), 'minutes').utc().valueOf());
        });

        $(this.$refs.input).on('hide.daterangepicker', () => {
          this.$nextTick(() => {
            this.dateRangePicker.setStartDate(moment.utc(this.rawValue));
            this.dateRangePicker.setEndDate(moment.utc(this.rawValue));
          });
        });

        this.dateRangePicker = $(this.$refs.input).data('daterangepicker');

        this.dateRangePicker.setStartDate(moment.utc(this.rawValue));
        this.dateRangePicker.setEndDate(moment.utc(this.rawValue));
      }, 100);
    },
    dateSelected (newUtc) {
      this.rawValue = newUtc;
      this.$emit('update:value', this.seconds ? parseInt(newUtc / 1000) : newUtc);
      this.$emit('update', this.seconds ? parseInt(newUtc / 1000) : newUtc);
      this.$nextTick(() => { this.updateRawValue(); });
    },
    updateRawValue () {
      if (this.value == null) { return; }

      this.rawValue = this.seconds ? this.value * 1000 : this.value;

      if (this.readonly || !this.dateRangePicker) { return; } // if readonly there is no dateRangePicker
      this.dateRangePicker.setStartDate(moment.utc(this.rawValue));
      this.dateRangePicker.setEndDate(moment.utc(this.rawValue));
    },
    focusInput () {
      this.$refs.input.focus();
    }
  },
  mounted () {
    if (HigJS.num.isNum(this.value)) {
      this.rawValue = this.seconds ? this.value * 1000 : this.value;
    }

    this.$nextTick(() => { this.init(); });
  },
  beforeUnmount () {
    if (this.dateRangePicker != null) { this.dateRangePicker.remove(); }
  }
};
</script>

<style scoped>
.VIDatepicker{
  display: flex;
  flex-flow: column nowrap;
  width: 250px;
  padding: 5px 2px;
}

.VIDatepicker.readonly{
pointer-events: none;
}

.VIDatepicker > .label{
  text-align: left;
  padding: 0 10px 5px 8px;
  cursor: pointer;
  color: var(--color-highlight);
}

.VIDatepicker > .input{
  border-radius: 16px;
  height: 31px;
  border: 2px solid var(--color-secondary-1);
  background-color: var(--color-secondary-1);
  color: var(--color-secondary-text);
  outline: none;
  padding: 0px 9px;
  font-size: 1em;
  font-weight: 600;
}

.VIDatepicker > .input:focus{
  border-color: var(--color-highlight);
}
</style>

<style>
.VIDatepickerPopup *{ }

.VIDatepickerPopup{
  border: none;
  padding: 0;
  box-shadow: 0 0 19px -3px #000000d9;

  background: var(--color-secondary);
  color: var(--color-secondary-text);
  border-radius: 6px;
}

.VIDatepickerPopup .ranges{
  color: var(--color-secondary-text);
  margin: 0 !important;
  padding: 6px 0;
  border-right: 1px solid var(--color-secondary-2);
}

.VIDatepickerPopup .ranges li:hover{
  background: var(--color-secondary-2);
  color: var(--color-secondary-text);
}

.VIDatepickerPopup .ranges li.active{
  background: var(--color-highlight);
  color: var(--color-highlight-text);
}

.VIDatepickerPopup .calendar-table {
  border-radius: 4px !important;
}

.VIDatepickerPopup .calendar-table table {
  color: #212121;
}

.VIDatepickerPopup .monthselect, .VIDatepickerPopup .yearselect{
  border-radius: 4px;
  outline: none;
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary-text);
}

.VIDatepickerPopup .monthselect:hover, .VIDatepickerPopup .yearselect:hover{
  border: 1px solid var(--color-highlight);
}

.VIDatepickerPopup .monthselect:active, .VIDatepickerPopup .yearselect:active{
  border: 1px solid var(--color-highlight);
}

.VIDatepickerPopup td.in-range {
  background-color: var(--color-secondary);
  color: var(--color-secondary-text);
}

.VIDatepickerPopup td.in-range:hover{
  background-color: var(--color-secondary-2);
  color: var(--color-secondary-text);
}

.VIDatepickerPopup td.today, .VIDatepickerPopup td.today:hover {
  background-color: var(--color-highlight-3);
}

.VIDatepickerPopup td.active, .VIDatepickerPopup td.active:hover {
  background-color: var(--color-highlight);
  border-color: transparent;
  color: var(--color-highlight-text);
}

.VIDatepickerPopup select.hourselect, .VIDatepickerPopup select.minuteselect, .VIDatepickerPopup select.secondselect, .VIDatepickerPopup select.ampmselect {
  background: none;
  border: 2px solid var(--color-secondary-1);
  background-color: var(--color-secondary-1);
  color: var(--color-secondary-text);
  text-align: center;
  border-radius: 20px;
}

.VIDatepickerPopup select.hourselect:focus, .VIDatepickerPopup select.minuteselect:focus, .VIDatepickerPopup select.secondselect:focus, .VIDatepickerPopup select.ampmselect:focus {
  border-color: var(--color-highlight);
}

.VIDatepickerPopup select.hourselect option, .VIDatepickerPopup select.minuteselect option, .VIDatepickerPopup select.secondselect option, .VIDatepickerPopup select.ampmselect option {
  background: var(--color-secondary-1);
  color: var(--color-secondary-text);
}

.VIDatepickerPopup.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid var(--color-highlight-3);
}

.VIDatepickerPopup.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid var(--color-highlight-3);
}

.VIDatepickerPopup:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid var(--color-highlight-3);
}

.VIDatepickerPopup:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--color-highlight-3);
  border-left: 6px solid transparent;
}

.VIDatepickerPopup.noArrow::before{
  border:none;
}

.VIDatepickerPopup.noArrow::after{
  border:none;
}

.VIDatepickerPopup.show-ranges .drp-calendar.left {
  border-left: 0px;
}

.VIDatepickerPopup.single .ranges{
  border-right: none;
}

.VIDatepickerPopup.single .drp-calendar.left{
  padding-right: 8px;
}

.VIDatepickerPopup.single .calendar-table{
  padding-right: 0px !important;
}

.VIDatepickerPopup .drp-buttons {
  border-top: 1px solid var(--color-secondary-2);
}

.VIDatepickerPopup .drp-buttons .btn {
  outline: none;
  border-radius: 20px;
  padding: 3px 10px;
  cursor: pointer;

  border: none;
  background-color: var(--color-highlight);
  color: var(--color-highlight-text);
}

.VIDatepickerPopup .drp-buttons .btn:hover {
  background-color: var(--color-highlight-3);
  color: var(--color-highlight-text);
}

.VIDatepickerPopup .drp-buttons .btn:active {
  background-color: var(--color-highlight-text);
  color: var(--color-highlight);
}
</style>
