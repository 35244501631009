import _ from 'lodash';

import Utils from '../../libs/utils';
import Nodes from './lib/nodesUtils.js';

import NodesStoreModule from './store/nodes.js';
import ConfigStoreModule from './store/config.js';

/* global HigJS */

const TYPE_ARR = 1;
const TYPE_OBJ = 2;

async function loadConfig ($store, pageId, filters) {
  if (!pageId) {
    console.warn('No pageId specified on config loading');
    return [];
  }

  if (filters && filters.nodeType) {
    const nodeTypesById = await Nodes.getAllNodeTypes($store);
    filters.nodeTypeId = Object.keys(nodeTypesById).find((nodeTypeId) => { return filters.nodeType === nodeTypesById[nodeTypeId]; });
  }

  let storeHash = pageId;
  if (filters) {
    if (filters.nodeId) { storeHash += '-nodeId:' + filters.nodeId; }
    if (filters.nodeTypeId) { storeHash += '-nodeTypeId:' + filters.nodeTypeId; }
  }

  if (!$store.state.Config.configs[storeHash]) {
    const configs = await Utils.ajaxRequest({
      act: 'pagesConfigsCRUD',
      data: {
        act: 'read',
        pageId,
        nodeId: (filters && filters.nodeId),
        nodeTypeId: (filters && filters.nodeTypeId)
      }
    }).catch((err) => {
      HigJS.debug.error('[loadConfig] ' + err);
      return [];
    });

    let finalConfig;
    let objConf = false; let arrConf = false;
    configs.forEach((conf) => {
      let curConf;

      try {
        // eslint-disable-next-line no-eval
        curConf = eval(conf.config);
      } catch (err) { console.error('Error evaluating a configuration object: "' + conf.config + '"'); console.error(err); }

      if (!objConf && !arrConf) {
        if (Array.isArray(curConf)) { arrConf = true; finalConfig = []; } else { objConf = true; finalConfig = {}; }
      }

      if (objConf) {
        finalConfig = _.merge(finalConfig, curConf);
      } else if (Array.isArray(curConf)) {
        for (let i = 0; i < curConf.length; i++) {
          finalConfig[i] = _.merge(finalConfig[i], curConf[i]);
        }
      }
    });

    $store.commit('SET_CONFIG', { configId: storeHash, config: finalConfig });
  }

  return $store.state.Config.configs[storeHash];
}

export default {
  install: function (app) {
    if (!app.config.globalProperties.$store) { console.warn('[MCC Plugin] VUEX Store is required to use this plugin'); return; }

    const global = {};

    // ######### NODES AND DATA #########
    global.$getData = (requests) => {
      if (typeof requests !== 'object') { console.error('[$getData] Requests must be object or Array'); return undefined; }
      const inputType = Array.isArray(requests) ? TYPE_ARR : TYPE_OBJ;
      requests = HigJS.obj.advClone(requests);

      // server-side functions expected timestamps in seconds but interface
      // wants them in milliseconds format (especially for Highcharts).
      for (let r in requests) {
        r = requests[r];
        // if r.interval is "last", keep it as is
        if (typeof r.interval === 'object') {
          r.interval.start = parseInt(r.interval.start / 1000);
          r.interval.stop = parseInt(r.interval.stop / 1000);
        }
      }

      return Utils.ajaxRequest({ act: 'VIGetData', data: { requests: requests } }, '/mccCore')
        .then((data) => {
          const res = inputType === TYPE_ARR ? [] : {};

          for (const key in data) {
            if (data[key].err) {
              console.error('[$getData] Error getting data: ' + data[key].err, requests[key]);
              res[key] = undefined;
            } else {
              // reconvert the timestamp to ms before the assignment
              if (Array.isArray(data[key].data)) {
                for (const d of data[key].data) { d[0] *= 1000; }
              }
              res[key] = data[key].data;
            }
          }

          return res;
        })
        .catch((err) => {
          HigJS.debug.error('[$getData] ' + err);
          return undefined;
        });
    };

    global.$getDataDaemon = function (requests, callback, period) {
      if (typeof requests !== 'object' && typeof requests !== 'function') { console.error('[$getDataDaemon] Requests must be object, Array or function'); return undefined; }

      const getData = function (finish) {
        let tmpRequests;
        if (typeof requests === 'function') { tmpRequests = requests(); } else { tmpRequests = HigJS.obj.clone(requests); }

        if (tmpRequests == null) { finish(undefined); return; }

        global.$getData(tmpRequests).then((data) => {
          finish(data);
        });
      };

      const daemon = new Utils.Daemon((finish) => {
        getData((data) => {
          if (data != null) { callback(data); }
          finish();
        });
      }, period);

      Object.defineProperty(daemon, 'getData', { value: getData });

      daemon.start();

      return daemon;
    };

    global.$getStructures = (requests) => {
      if (typeof requests !== 'object') { console.error('[$getData] Requests must be object or Array'); return undefined; }
      const inputType = Array.isArray(requests) ? TYPE_ARR : TYPE_OBJ;

      return Utils.ajaxRequest({ act: 'VIGetStructures', data: { requests: requests } }, '/mccCore')
        .then((data) => {
          const res = inputType === TYPE_ARR ? [] : {};

          for (const key in data) {
            if (data[key].err) {
              console.error('[$getStructures] Error getting structure: ' + data[key].err, requests[key]);
              res[key] = undefined;
            } else {
              res[key] = data[key].data;
            }
          }

          return res;
        })
        .catch((err) => {
          HigJS.debug.error('[$getStructures] ' + err);
          return undefined;
        });
    };

    global.$getParameters = (requests) => {
      if (typeof requests !== 'object') { console.error('[$getData] Requests must be object or Array'); return undefined; }
      const inputType = Array.isArray(requests) ? TYPE_ARR : TYPE_OBJ;
      const $store = app.config.globalProperties.$store;

      const realRequests = {};
      for (const key in requests) {
        if (!requests[key].nodeId || !requests[key].parameter) { console.warn('parameter not found', requests[key]); }

        if (!$store.state.Nodes.parameters[requests[key].nodeId] || !$store.state.Nodes.parameters[requests[key].nodeId][requests[key].parameter]) {
          realRequests[requests[key].nodeId + '_' + requests[key].parameter] = requests[key];
        }
      }

      return new Promise((resolve) => {
        if (Object.keys(realRequests).length > 0) {
          return Utils.ajaxRequest({ act: 'VIGetParameters', data: { requests: realRequests } }, '/mccCore')
            .then((data) => {
              const newParameters = {};
              for (const key in data) {
                if (data[key].err) {
                  console.error('[$getParameters] Error getting parameter: ' + data[key].err, realRequests[key]);
                } else {
                  if (!newParameters[realRequests[key].nodeId]) { newParameters[realRequests[key].nodeId] = {}; }

                  newParameters[realRequests[key].nodeId][realRequests[key].parameter] = data[key].data;
                }
              }

              $store.commit('UPDATE_PARAMETERS', newParameters);
              resolve();
            }).catch((err) => {
              HigJS.debug.error('[$getParameters] ' + err);
            });
        } else {
          resolve();
        }
      }).then(() => {
        const res = inputType === TYPE_ARR ? [] : {};

        for (const key in requests) {
          if ($store.state.Nodes.parameters[requests[key].nodeId] && $store.state.Nodes.parameters[requests[key].nodeId][requests[key].parameter]) {
            res[key] = $store.state.Nodes.parameters[requests[key].nodeId][requests[key].parameter];
          } else {
            res[key] = undefined;
          }
        }

        return res;
      }).catch((err) => {
        console.error('[$getParameters] Error on VIGetParameters request', err);
        return undefined;
      });
    };

    global.$loadAllNodes = function () {
      console.warn('$loadAllNodes is deprecated, please use $getAllNodes');
      app.config.globalProperties.$store.dispatch('loadAllNodes');
    };

    global.$getAllNodes = function () {
      return Nodes.getAllUserNodes(app.config.globalProperties.$store);
    };

    global.$loadNodes = function (nodesIds, loadChilds) {
      console.warn('$loadNodes is deprecated, please use $getNodes');
      app.config.globalProperties.$store.dispatch('loadNodes', { nodesIds, loadChilds });
    };

    global.$getNodes = function (nodesIds, getChilds) {
      return Nodes.getNodesCaching(app.config.globalProperties.$store, nodesIds, getChilds);
    };

    global.$getNodesPartially = function (nodesIds, opts) {
      return Nodes.getNodesPartiallyCaching(app.config.globalProperties.$store, nodesIds, opts);
    };

    global.$getNodesFull = function (nodesIds) {
      return Nodes.getNodesFullCaching(app.config.globalProperties.$store, nodesIds);
    };

    global.$flushNodes = function (nodesIds) {
      return app.config.globalProperties.$store.commit('FLUSH_NODES', nodesIds);
    };

    global.$getPlantsList = function (nodeVarsToLoad) {
      return Nodes.getPlantsList(nodeVarsToLoad);
    };

    global.$getPlantInfo = function (plantId, nodeVarsToLoad) {
      return Nodes.getPlantInfo(app.config.globalProperties.$store, plantId, nodeVarsToLoad);
    };

    global.$getPlantsInfo = function (plantsIds, nodeVarsToLoad) {
      return Nodes.getPlantsInfo(app.config.globalProperties.$store, plantsIds, nodeVarsToLoad);
    };

    global.$getNodeChilds = function (parentId, childsType) {
      console.warn('$getNodeChilds is deprecated, please use $getNodeChildsOfType');
      const allNodes = app.config.globalProperties.$store.state.Nodes.nodes;

      let ret;
      if (!allNodes[parentId]) { ret = []; } else {
        ret = Object.keys(allNodes).filter((nodeId) => {
          return (allNodes[nodeId].path_string && allNodes[nodeId].path_string.split('.').indexOf(String(parentId)) > -1) && (!childsType || allNodes[nodeId].type === childsType);
        }).map((nodeId) => { return allNodes[nodeId]; });
      }

      Object.defineProperty(ret, 'includeParent', {
        value: function () {
          if (allNodes[parentId]) {
            this.unshift(allNodes[parentId]);
          }
          return this;
        }
      });

      Object.defineProperty(ret, 'byId', {
        value: function () {
          return this.reduce((acc, node) => {
            acc[node.id] = node;
            return acc;
          }, {});
        }
      });

      return ret;
    };

    global.$getNodeChildsOfType = function (parentId, childsType) {
      return new Promise((resolve) => {
        resolve(Nodes.getNodesCaching(app.config.globalProperties.$store, [parentId], true)); // get all involved nodes
      }).then((nodes) => {
        const childNodes = Object.keys(nodes).filter((nodeId) => {
          if (Number(nodeId) !== Number(parentId) &&
              (nodes[nodeId].path_string && nodes[nodeId].path_string.split('.').indexOf(String(parentId)) > -1) &&
              (!childsType || nodes[nodeId].type === childsType)) {
            return true;
          }
          return false;
        }).map((nodeId) => { return nodes[nodeId]; });

        return childNodes;
      });
    };

    global.$getNodeTypes = function () {
      return Nodes.getAllNodeTypes(app.config.globalProperties.$store);
    };

    global.$getVarPeriods = function () {
      return Nodes.getAllVarPeriods(app.config.globalProperties.$store);
    };

    global.$getVarTypes = function () {
      return Nodes.getAllVarTypes(app.config.globalProperties.$store);
    };

    global.$getConfiguration = function (pageId, filters) {
      return loadConfig(app.config.globalProperties.$store, pageId, filters);
    };

    app.config.globalProperties.$store.registerModule('Nodes', NodesStoreModule);
    app.config.globalProperties.$store.registerModule('Config', ConfigStoreModule);

    // ######### NODES AND DATA #########

    Object.keys(global).forEach((key) => { app.provide(key, global[key]); });
    Object.assign(app.config.globalProperties, global);
    Object.assign(app.config.globalProperties.$store, global);
  }
};
