<template>
  <div :class="[ 'VIChart', { 'loading': loading, 'updateBlur': blurOnUpdate && updating }]" :id="id" ref="container" v-bind="$attrs"></div>
  <VI-Placeholder :loading="loading" :component="chartContainer"/>
</template>

<script>
/* global HigJS, Highcharts */
import Utils from '../libs/utils';
import VIPlaceholder from './VI-Placeholder.vue';
import _ from 'lodash';

export default {
  name: 'VI-Chart',
  components: {
    VIPlaceholder
  },
  props: {
    series: { type: [Object, Array], default: function () { return {}; } }, // each parameter is a series, the param name is the series id
    data: { type: Object, default: function () { return {}; } }, // each parameter is the data of a series, data is in this format: [ [ utc1, data1 ], [ utc2, data2 ], .... ]
    yAxis: { type: [Object, Array], default: function () { return []; } },
    xAxis: { type: [Object, Array], default: function () { return []; } },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    options: { type: Object, default: function () { return {}; } },
    debounce: { type: Number, default: 500 },
    loading: { type: Boolean, default: false },
    blurOnUpdate: { type: Boolean, default: false }
  },
  data () {
    let uniqueId;
    do {
      uniqueId = 'chart_' + (Math.random() * 1000000000);
    } while (document.getElementById(uniqueId) != null);

    return {
      id: uniqueId,
      chart: undefined,
      chartContainer: undefined,
      dataUpdateTo: undefined,
      confUpdateTo: undefined,
      confToUpdate: {},
      updating: false
    };
  },
  computed: {
    $_series: function () {
      const ret = [];

      if (Array.isArray(this.series)) {
        for (let i = 0; i < this.series.length; i++) {
          if (this.series[i].disabled || !this.series[i].id) { continue; }

          ret.push(Utils.advExtendObj(_.cloneDeep(this.series[i]), { states: { inactive: { opacity: 1 } } }));
        }
      } else {
        for (const seriesId in this.series) {
          if (this.series[seriesId].disabled) { continue; }

          const curSeries = _.cloneDeep(this.series[seriesId]);
          curSeries.id = seriesId;
          ret.push(Utils.advExtendObj(curSeries, { states: { inactive: { opacity: 1 } } }));
        }
      }

      // console.log(this.series, ret);
      return ret;
    },
    $_yAxis: function () {
      let yAxisClone = _.cloneDeep(this.yAxis);

      if (!Array.isArray(yAxisClone)) {
        yAxisClone = Object.keys(yAxisClone).reduce((acc, axisId) => {
          acc.push({ id: axisId, ...yAxisClone[axisId] });
          return acc;
        }, []);
      }

      if (yAxisClone.length === 0) { yAxisClone.push({}); } // by default put one element in yAxis

      const res = yAxisClone.map((el) => {
        return Utils.advExtendObj(el, {
          labels: {
            style: { color: 'var(--text-color)' },
            formatter: function () { return HigJS.num.format(this.value); }
          },
          title: {
            text: '',
            style: { color: 'var(--text-color)' },
            margin: 3
          },
          lineColor: 'var(--axis-color)',
          tickColor: 'var(--axis-color)',
          gridLineColor: 'var(--axis-color)'
        });
      });

      return res;
    },
    $_xAxis: function () {
      let xAxisClone = _.cloneDeep(this.xAxis);

      if (!Array.isArray(xAxisClone)) {
        xAxisClone = Object.keys(xAxisClone).reduce((acc, axisId) => {
          acc.push({ id: axisId, ...xAxisClone[axisId] });
          return acc;
        }, []);
      }

      if (xAxisClone.length === 0) { xAxisClone.push({}); } // by default put one element in xAxis

      const res = xAxisClone.map((el) => {
        return Utils.advExtendObj(el, {
          endOnTick: false,
          type: 'datetime',
          id: 'xAxe',
          labels: {
            style: { color: 'var(--text-color)' }
          },
          title: {
            text: '',
            style: { color: 'var(--text-color)' },
            margin: 3
          },
          lineColor: 'var(--axis-color)',
          tickColor: 'var(--axis-color)',
          gridLineColor: 'var(--axis-color)',
          dateTimeLabelFormats: { month: '%e. %b', year: '%b' },
          minRange: 1
        });
      });

      return res;
    },
    $_options: function () {
      const opt = _.cloneDeep(this.options);

      if (opt.chart) {
        opt.chart = Utils.advExtendObj(opt.chart, { zoomType: 'xy', backgroundColor: 'none' });
      }

      return opt;
    }
  },
  watch: {
    $_series: {
      deep: true,
      handler: function () {
        this.updateConf('series');
      }
    },
    $_options: {
      deep: true,
      handler: function () {
        this.updateConf('options');
      }
    },
    $_yAxis: {
      deep: true,
      handler: function () {
        this.updateConf('yAxis');
      }
    },
    $_xAxis: {
      deep: true,
      handler: function () {
        this.updateConf('xAxis');
      }
    },
    data: {
      deep: true,
      handler: function () {
        this.updateData();
      }
    },
    title: function () {
      this.chart.setTitle({ text: this.title, style: { color: 'var(--text-color)' } });
    }
  },
  mounted () {
    this.drawChart();
    this.updateData(true);

    this.$nextTick(() => { this.chartContainer = this.$refs.container; });
  },
  unmounted () {
    this.chart.destroy();
    this.chart = undefined;
  },
  methods: {
    updateConf (confType) { // all this is to have a smoother (and probably more responsive) interface
      if (!this.chart) { return; }

      this.confToUpdate[confType] = true;
      this.updating = true;

      // console.log(confType + ' update requested');

      clearTimeout(this.confUpdateTo);
      this.confUpdateTo = setTimeout(() => {
        if (!this.chart) { return; }
        // console.log('Updating: ' + Object.keys( this.confToUpdate ).join(', '), this.$_series );

        if (this.confToUpdate.options) { this.chart.update(this.$_options, false, false); }
        if (this.confToUpdate.yAxis) { this.chart.update({ yAxis: this.$_yAxis }, false, true); }
        if (this.confToUpdate.xAxis) { this.chart.update({ xAxis: this.$_xAxis }, false, true); }
        if (this.confToUpdate.series) { this.chart.update({ series: this.$_series }, false, true); }
        this.confToUpdate = {};

        this.updateData(true);
      }, this.debounce);
    },
    updateData (immediate) {
      if (!this.chart) { return; }

      this.updating = true;
      clearTimeout(this.dataUpdateTo);
      this.dataUpdateTo = setTimeout(() => {
        if (!this.chart) { return; }

        for (const seriesId in this.data) {
          const curSeries = this.chart.get(seriesId);
          if (curSeries) { curSeries.setData(_.cloneDeep(this.data[seriesId]), false); }
        }
        this.chart.redraw();
        this.toggleLegend();
        this.updating = false;
      }, immediate ? 0 : this.debounce);
    },
    drawChart () {
      if (this.chart) { this.chart.destroy(); }
      this.chart = Highcharts.chart(this.id, Utils.advExtendObj(this.$_options, {
        chart: { zoomType: 'xy', backgroundColor: 'none' },
        title: { text: this.title, style: { color: 'var(--text-color)' } },
        subtitle: { text: this.subtitle, style: { color: 'var(--text-color)' } },
        yAxis: this.$_yAxis,
        xAxis: this.$_xAxis,
        credits: { enabled: false },
        plotOptions: {
          series: {
            label: { connectorAllowed: false }
          }
        },
        legend: {
          itemStyle: { color: 'var(--text-color)' },
          itemHoverStyle: { color: 'var(--text-color)' }
        },
        series: this.$_series,
        time: { useUTC: true }
      }));
    },
    toggleLegend () {
      this.toggleAll = true;
      if (this.chart && this.chart.legend && this.chart.legend.allItems && Array.isArray(this.chart.legend.allItems) && this.chart.series && this.chart.series.length > 0) {
        this.chart.legend.allItems.forEach((item, i) => {
          if (item?.legendGroup?.element) {
            item.legendGroup.element.ondblclick = () => {
              this.chart.series.forEach((serie, j) => {
                if (i !== j) {
                  if (this.toggleAll) {
                    serie.hide();
                  } else {
                    serie.show();
                  }
                }
              });
              this.toggleAll = !this.toggleAll;
            };
          }
        });
      }
    },
    reflow () {
      this.chart.reflow();
    }
  }
};
</script>

<style scoped>
.VIChart{
  width: 100%;
  height: 100%;
  /* transition: opacity .3s ease; */

  --axis-color: var(--color-primary-1);
  --text-color: var(--color-primary-text);
}

.VIChart.loading{
  opacity: 0;
}

.VIChart.updateBlur{
  /* filter: blur(3px); */
  opacity: .1;
}
</style>
