<template>
  <div class="VITable" :style="tableColors">
    <div class="table" ref="table" @scroll="tableScroll">
      <VI-Table-head
        v-for="(col, colI) in parsed_cols"
        :ref="el => { headerCellsRefs[col.name] = el; }"
        :class="['cellHead', { 'stickyV': stickyHeader, 'stickyH': !!stickyStyle[colI], 'stickyActive': stickyIsActive[colI] }, 'col_'+colI ]"
        :style="stickyStyle[colI]"
        :key="col.name"
        :value="col.label || ''"
        :searchable="col.searchable"
        :orderable="col.orderable"
        :order="colOrder.name == col.name ? colOrder.direction : 0"
        v-on:column-search="columnSearch(col.name, $event)"
        v-on:column-order="columnOrder(col.name, $event)"
        v-on:cell-click="headClicked( colI )"
      />
      <VI-Table-cell
        v-for="{ X, Y } in page_cellsXY" :key="X+'-'+Y"
        :class="['cellData', { 'stickyH': !!stickyStyle[X], 'stickyActive': stickyIsActive[X], 'highlighted': (highlighted[Y] == true || (highlighted[Y] && highlighted[Y][parsed_cols[X].name] == true)) }, 'col_'+X, 'row_'+Y]"
        :title="((title[Y] && title[Y][parsed_cols[X].name]) || '')"
        @mouseenter="cellHover(X, Y)"
        @mouseleave="cellHoverOff(X, Y)"
        :style="[stickyStyle[X], (old[Y] == true || (old[Y] && old[Y][parsed_cols[X].name] == true)) ? { color: oldColor } : null ]"
        :value="data[Y][parsed_cols[X].name] != undefined ? data[Y][parsed_cols[X].name] : ''"
        v-on:update="dataUpdate(Y, parsed_cols[X].name, $event)"
        :type="parsed_cols[X].type"
        :icon="parsed_cols[X].icon"
        :buttonLabel="parsed_cols[X].buttonLabel"
        :selectOptions="parsed_cols[X].selectOptions"
        v-on:cell-click="cellClicked(X, Y)"
        v-on:button-click="parsed_cols[X].action && parsed_cols[X].action(Y, data[Y][parsed_cols[X].name])"
      />
    </div>
    <div class="footerDiv" v-if="showFooter">
      <div class="pageNav" v-if="pagination">
        <VI-Icon :class="[ 'pageNavArrow', { 'clickable': currentPage > 1 } ]" type="angle-double-left" @click="currentPage = 1"/>
        <VI-Icon :class="[ 'pageNavArrow', { 'clickable': currentPage > 1 } ]" type="angle-left" @click="currentPage = Math.max(currentPage-1, 1)"/>
        <div v-for="(page, i) in pagesButtons" :key="i" :class="[ 'pageNavNumber', { 'clickable': !!page, 'active': currentPage == page }]" @click="currentPage = page || currentPage">{{page || ""}}</div>
        <VI-Icon :class="[ 'pageNavArrow', { 'clickable': currentPage < pagesCount } ]" type="angle-right" @click="currentPage = Math.min(currentPage+1, pagesCount)"/>
        <VI-Icon :class="[ 'pageNavArrow', { 'clickable': currentPage < pagesCount } ]" type="angle-double-right" @click="currentPage = pagesCount"/>
      </div>
    </div>
  </div>
</template>

<script>
/* global HigJS */

import VITableHead from './VI-Table-head.vue';
import VITableCell from './VI-Table-cell.vue';
import VIIcon from './VI-Icon.vue';

export default {
  name: 'VI-Table',
  components: {
    VITableHead,
    VITableCell,
    VIIcon
  },
  props: {
    cols: { type: Array, default: function () { return []; } },
    data: { type: Array, default: function () { return []; } },
    highlighted: { type: Object, default: function () { return {}; } }, // Object indexed by row index and col name
    old: { type: Object, default: function () { return {}; } }, // Object indexed by row index and col name
    oldColor: { type: String, default: 'var(--color-error)' },
    title: { type: Object, default: function () { return {}; } }, // Object indexed by row index and col name
    options: { type: Object, default: function () { return {}; } },
    globFilter: { type: String, default: '' }
  },
  data () {
    return {
      headerCellsRefs: {},
      stickyLeft: this.cols.map(() => { return undefined; }),
      stickyIsActive: this.cols.map(() => { return false; }),
      lastScrollLeft: 0,
      colsFilters: {},
      colOrder: this.options.initialOrder && this.options.initialOrder.name && this.options.initialOrder.direction ? this.options.initialOrder : { name: '', direction: 0 },
      headerHeight: 42,
      rowsPerPageCalculated: 1,
      pagesCount: 1,
      currentPage: 1,
      dataIndexes: this.data.map((row, index) => { return index; })
    };
  },
  computed: {
    // ### OPTIONS ###
    stickyHeader: function () { return typeof this.options.stickyHeader !== 'undefined' ? this.options.stickyHeader : true; },
    compactRows: function () { return typeof this.options.compactRows !== 'undefined' ? this.options.compactRows : false; },
    pagination: function () {
      // Chrome have a 1000 row limit for css grids, so if the data is over that limit force pagination
      // (Best solution would be to implement some kind of dynamic loading list that display only part of the data based on scroll position, this would be event better from a performance standpoint)
      if (this.data.length > 999) { console.warn('Warning, cannot display more than 999 rows without pagination'); return true; }
      if (typeof this.options.pagination === 'number') return this.data.length >= this.options.pagination;
      return typeof this.options.pagination === 'boolean' ? this.options.pagination : false;
    },
    rowsPerPage: function () { return typeof this.options.rowsPerPage !== 'undefined' ? this.options.rowsPerPage : this.rowsPerPageCalculated; },
    onCellClick: function () { return typeof this.options.onCellClick === 'function' ? this.options.onCellClick : undefined; },
    onHeadClick: function () { return typeof this.options.onHeadClick === 'function' ? this.options.onHeadClick : undefined; },
    tableColors: function () {
      return {
        '--bgColor': this.options.bgColor || '',
        '--textColor': this.options.textColor || ''
      };
    },

    // ### OPTIONS ###

    rowHeight: function () {
      return this.compactRows ? 30 : 40;
    },
    showFooter: function () { // put there in case some other element needs to be added to the footer
      return this.pagination;
    },
    page_cellsXY: function () {
      const cellsXY = [];

      for (let i = 0; i < this.pageDataIndexes.length; i++) {
        for (let j = 0; j < this.parsed_cols.length; j++) {
          cellsXY.push({ X: j, Y: this.pageDataIndexes[i] });
        }
      }

      return cellsXY;
    },
    parsed_cols: function () {
      let cols = HigJS.obj.clone(this.cols).filter((curCol) => { return !curCol.hidden; });

      /*
            col: {
                name: String
                label: String
                type: String
                sticky: Boolean
                width: Number || String
                searchable: Boolean
                orderable: Boolean
                icon: String
                buttonLabel: String
                selectOptions: Object
                action: Function
                onCellClick: Function
            }
            */

      cols = cols.map((col) => {
        if (!isNaN(Number(col.width))) { col.width = col.width + 'px'; }

        switch (col.type) {
          case 'checkbox':
            col.searchable = false;
            col.orderable = false;
            col.disableCellClick = true;
            break;
          case 'radio':
            col.searchable = false;
            col.orderable = false;
            col.disableCellClick = true;
            break;
          case 'input':
            col.disableCellClick = true;
            break;
          case 'select':
            if (!col.selectOptions) { console.warn('Column ' + col.name + " of type 'select' is missing 'selectOptions'"); }
            col.disableCellClick = true;
            break;
          case 'iconButton':
            if (!col.icon) { console.warn('Column ' + col.name + " of type 'iconButton' is missing 'icon'"); }
            if (!col.action) { console.warn('Column ' + col.name + " of type 'iconButton' is missing 'action'"); }
            col.searchable = false;
            col.orderable = false;
            col.disableCellClick = true;
            break;
          case 'button':
            if (!col.buttonLabel) { console.warn('Column ' + col.name + " of type 'button' is missing 'buttonLabel'"); }
            if (!col.action) { console.warn('Column ' + col.name + " of type 'button' is missing 'action'"); }
            col.searchable = false;
            col.orderable = false;
            col.disableCellClick = true;
            break;
          default:
            col.type = undefined; // reset any other type so it takes the standard value
            break;
        }

        return col;
      });

      return cols;
    },
    stickyStyle: function () {
      return this.stickyLeft.map((left) => { return left == null ? '' : 'left: ' + left + 'px'; });
    },
    pageDataIndexes: function () {
      // console.time("Pagination");

      let ret = this.dataIndexes;

      if (this.pagination) {
        ret = ret.slice((this.currentPage - 1) * this.rowsPerPage, this.currentPage * this.rowsPerPage);
      }

      // console.timeEnd("Pagination");

      return ret;
    },
    pagesButtons: function () {
      const pages = []; const pagesToShow = 5;

      for (let i = -parseInt(pagesToShow / 2); i <= parseInt(pagesToShow / 2); i++) {
        const curPage = this.currentPage + i;
        pages.push((curPage < 1 || curPage > this.pagesCount) ? null : curPage);
      }
      return pages;
    }
  },
  watch: {
    parsed_cols: {
      deep: true,
      handler: function () { this.reflowColumns(); }
    },
    data: {
      deep: true,
      handler: function () { this.applyFilter(); }
    },
    globFilter: function () { this.applyFilter(); },
    colsFilters: {
      deep: true,
      handler: function () { this.applyFilter(); }
    },
    colOrder: {
      deep: true,
      handler: function () { this.applyOrder(); }
    },
    rowHeight: function () {
      this.reflowRows();
      this.$nextTick(() => { requestAnimationFrame(() => { this.rowsPerPageCalculated = this.calcRowsPerPage(); }); });
    },
    rowsPerPage: function () { this.calcPagesCount(); },
    pageDataIndexes: function () { this.reflowRows(); }
  },
  methods: {
    applyFilter () {
      // console.time("FilterFast");
      const contains = (a, b) => { return String(a).toLowerCase().indexOf(b) > -1; };

      const globFilter = this.globFilter ? String(this.globFilter).toLowerCase() : false;
      // const filterFunc = ( rowI ) => {
      //     let globMatch = false;

      //     for( let i = 0; i < this.parsed_cols.length; i++ ){
      //         if( this.data[rowI][ this.parsed_cols[i].name ] != undefined ){
      //             if( this.colsFilters[ this.parsed_cols[i].name ] ){
      //                 if( !contains( this.data[rowI][ this.parsed_cols[i].name ], this.colsFilters[ this.parsed_cols[i].name ] ) ){ return false; }
      //             }
      //             if( globFilter != '' ){
      //                 if( contains( this.data[rowI][ this.parsed_cols[i].name ], globFilter ) ){ globMatch = true; }
      //             }
      //         }
      //     }

      //     return globFilter == '' ? true : globMatch;
      // };

      // this.dataIndexes = this.data.map(( row, index ) => { return index; });
      // this.dataIndexes = this.dataIndexes.filter(filterFunc);

      const data = this.data; // for some strange reason doing this is faster (specially if there is no filter to apply)

      const indexes = [];
      for (let k = 0; k < data.length; k++) {
        let globMatch = false;
        let skipRow = false;

        for (let i = 0; i < this.parsed_cols.length; i++) {
          if (data[k][this.parsed_cols[i].name] != null) {
            if (this.colsFilters[this.parsed_cols[i].name]) {
              if (!contains(data[k][this.parsed_cols[i].name], this.colsFilters[this.parsed_cols[i].name])) { skipRow = true; break; }
            }
            if (globFilter !== false) {
              if (contains(data[k][this.parsed_cols[i].name], globFilter)) { globMatch = true; }
            }
          }
        }

        if (!skipRow && (globFilter === false || globMatch)) {
          indexes.push(k);
        }
      }

      this.dataIndexes = indexes;

      // console.timeEnd("FilterFast");

      this.calcPagesCount();
      this.applyOrder();
    },
    applyOrder () {
      // console.time("Order");

      if (this.colOrder && this.colOrder.name !== '' && this.colOrder.direction != null) {
        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        const sortFunc = (a, b) => {
          if (this.data[a][this.colOrder.name] != null && this.data[b][this.colOrder.name] != null) {
            if (this.colOrder.direction > 0) {
              return collator.compare(String(this.data[a][this.colOrder.name]), String(this.data[b][this.colOrder.name]));
            } else if (this.colOrder.direction < 0) {
              return collator.compare(String(this.data[b][this.colOrder.name]), String(this.data[a][this.colOrder.name]));
            } else {
              return a < b ? -1 : 1;
            }
          }
          return 0;
        };

        this.dataIndexes = this.dataIndexes.sort(sortFunc);
      }

      // console.timeEnd("Order");
    },
    calcPagesCount () {
      this.pagesCount = Math.ceil(this.dataIndexes.length / this.rowsPerPage);
      this.currentPage = Math.min(this.currentPage, this.pagesCount) || 1;
    },
    calcRowsPerPage () {
      if (this.$refs.table) {
        const val = parseInt((this.$refs.table.offsetHeight - this.headerHeight - 42) / this.rowHeight);

        if (val > 0) return val;
        return parseInt((this.$refs.table.offsetParent.offsetHeight - this.headerHeight - 42) / this.rowHeight);
      }
      return 1;
    },
    dataUpdate (index, field, newVal) {
      const newData = HigJS.obj.clone(this.data);

      const curCol = this.cols.find((col) => { return col.name === field; });

      if (curCol && curCol.type === 'radio' && newVal === true) {
        for (let i = 0; i < newData.length; i++) {
          if (newData[i][field] === true) { newData[i][field] = false; }
        }
      }

      newData[index][field] = newVal;

      this.$emit('update:data', newData);
    },
    columnSearch (name, curFilter) {
      if (curFilter === '' && this.colsFilters[name]) {
        delete this.colsFilters[name];
      } else {
        this.colsFilters[name] = String(curFilter).toLowerCase();
      }
    },
    columnOrder (name, direction) {
      this.colOrder.name = name;
      this.colOrder.direction = direction;
    },
    reflowRows () {
      if (this.pagination) {
        this.$refs.table.style['grid-template-rows'] = this.headerHeight + 'px repeat(' + this.rowsPerPage + ', minmax(' + this.rowHeight + 'px, 1fr))';
      } else {
        this.$refs.table.style['grid-template-rows'] = this.headerHeight + 'px repeat(' + this.data.length + ', minmax(' + this.rowHeight + 'px, 1fr))';
      }
    },
    reflowColumns () {
      let curStickyOffset = 0;

      const gridColumnsWidth = [];
      this.parsed_cols.forEach((curCol) => {
        gridColumnsWidth.push(curCol.width ? curCol.width : 'minmax(max-content, 1fr)');
      });

      this.$refs.table.style['grid-template-columns'] = compressColsWidth(gridColumnsWidth).join(' ');

      requestAnimationFrame(() => {
        this.stickyLeft = this.cols.map((curCol) => {
          let curColLeft;
          if (curCol.sticky && this.headerCellsRefs[curCol.name]) {
            curColLeft = curStickyOffset;

            curStickyOffset += this.headerCellsRefs[curCol.name].getWidth();
          }
          return curColLeft;
        });
      });
    },
    tableScroll () { // TODO: if the sticky is activated on a cell this looses the hoverCell and hoverRow classes, the best approach should be to apply the class from here instead of letting vue do it
      if (!this.$refs.table) { return; }
      if (this.$refs.table.scrollLeft === this.lastScrollLeft) { return; } // left scroll is not changed, so no need to recalculate all this

      this.stickyIsActive = this.cols.map(() => { return false; });

      if (this.$refs.table.scrollLeft === 0) { return; }

      const tableLeft = this.$refs.table.getBoundingClientRect().left;
      for (let i = this.cols.length - 1; i >= 0; i--) {
        if (!this.cols[i].sticky) { continue; }
        if (this.headerCellsRefs[this.cols[i].name] && this.headerCellsRefs[this.cols[i].name].getLeft() - tableLeft === this.stickyLeft[i]) {
          this.stickyIsActive[i] = true;
          break;
        }
      }
      this.lastScrollLeft = this.$refs.table.scrollLeft;
    },
    cellHover: function (X, Y) {
      if (!this.$refs.table) { return; }

      // console.time('cellHover');
      Array.prototype.forEach.call(this.$refs.table.getElementsByClassName(`cellData row_${Y}`), (hoverRow) => {
        if (hoverRow.classList.contains(`col_${X}`)) { hoverRow.classList.add('hoverCell'); } else { hoverRow.classList.add('hoverRow'); }
      });
      // console.timeEnd('cellHover');
    },
    cellHoverOff: function (X, Y) {
      // console.time('cellHoverOff');
      Array.prototype.forEach.call(this.$refs.table.getElementsByClassName(`cellData row_${Y}`), (prevHoverCell) => {
        prevHoverCell.classList.remove('hoverRow', 'hoverCell');
      });
      // console.timeEnd('cellHoverOff');
    },
    cellClicked: function (X, Y) {
      if (this.parsed_cols[X].disableCellClick) { return; }
      if (this.parsed_cols[X].onCellClick) { this.parsed_cols[X].onCellClick(Y, this.parsed_cols[X].name, this.data[Y][this.parsed_cols[X].name]); }
      if (this.onCellClick) { this.onCellClick(Y, this.parsed_cols[X].name, this.data[Y][this.parsed_cols[X].name]); }
      this.$nextTick(() => { this.cellHover(X, Y); }); // this is ugly but it works pretty well
    },
    headClicked: function (X) {
      if (this.parsed_cols[X].onHeadClick) { this.parsed_cols[X].onHeadClick(this.parsed_cols[X].name); }
      if (this.onHeadClick) { this.onHeadClick(this.parsed_cols[X].name); }
    }
  },
  mounted () {
    this.applyFilter();

    this.$nextTick(() => {
      this.reflowColumns();
      this.reflowRows();
      requestAnimationFrame(() => {
        this.rowsPerPageCalculated = this.calcRowsPerPage();
      });
    });
  }
};

function compressColsWidth (widthsArray) {
  if (widthsArray.length === 0) { return []; }

  const ret = []; const acc = { val: undefined, rep: undefined };

  widthsArray.forEach((width, i) => {
    if (acc.val === width) {
      acc.rep++;
    } else {
      if (i > 0) { ret.push('repeat(' + acc.rep + ', ' + acc.val + ')'); }
      acc.val = width;
      acc.rep = 1;
    }
  });
  ret.push('repeat(' + acc.rep + ', ' + acc.val + ')');

  return ret;
}
</script>

<style scoped>
.VITable{
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;

  background: var(--bgColor, var(--color-primary));
  color: var(--textColor, var(--color-primary-text));
}

.VITable .table{
  width: 100%;
  height: 100%;

  background: inherit;
  color: inherit;

  overflow: auto;
  display: grid;
  justify-items: stretch;
  align-items: stretch;
}

.VITable .table > .cellHead{
  background: var(--color-primary-1);
  color: var(--color-primary-text);

  transition: box-shadow .3s ease;
}

.VITable .table > .cellHead.stickyV{
  position: sticky;
  top: 0;
  z-index: 2;
}

.VITable .table > .cellHead.stickyH{
  position: sticky;
  z-index: 3;
  /* left: 0px; <-- this will be added directly on the cell */
}

.VITable .table > .cellHead.stickyActive{
  box-shadow: inset -2px 0 0px 0px var(--color-primary-text);
}

.VITable .table > .cellData{
  background: inherit;
  color: inherit;
  position: relative;

  transition: box-shadow .3s ease;
}

.VITable .table > .cellData:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  margin: 3px;
  border-radius: 5px;
  pointer-events: none;

  transition: box-shadow .3s ease, backdrop-filter .3s ease;
}

.VITable .table > .cellData.hoverRow{
  background: var(--color-primary-2);
  color: var(--color-primary-text);
}

.VITable .table > .cellData.hoverCell{
  background: var(--color-primary-3);
  color: var(--color-primary-text);
}

.VITable .table > .cellData.stickyH{
  position: sticky;
  z-index: 2;
  /* left: 0px; <-- this will be added directly on the cell */
}

.VITable .table > .cellData.stickyActive{
  box-shadow: inset -2px 0 0px 0px var(--color-primary-text);
}

.VITable .table > .cellData.highlighted{}

.VITable .table > .cellData.highlighted:after{
  box-shadow: inset 0px 0 0 2px var(--color-highlight);
  backdrop-filter: brightness(0.8);
}

.VITable .footerDiv{
  background: var(--color-primary-1);
  color: var(--color-primary-text);

  width: 100%;
  flex: 0 0 auto;
  height: 42px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.VITable .footerDiv > .pageNav{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.VITable .footerDiv > .pageNav > .pageNavNumber{
  width: 30px;
  height: 30px;
  border-radius: 50%;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.VITable .footerDiv > .pageNav > .pageNavNumber.clickable{
  cursor: pointer;
}

.VITable .footerDiv > .pageNav > .pageNavNumber.clickable:hover{
  color: var(--color-highlight);
}

.VITable .footerDiv > .pageNav > .pageNavNumber.active,
.VITable .footerDiv > .pageNav > .pageNavNumber.active:hover{
  background: var(--color-highlight);
  color: var(--color-highlight-text);
}

.VITable .footerDiv > .pageNav > .pageNavArrow{
  width: 30px;
  height: 30px;
  font-size: 2em;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  opacity: .5;
}

.VITable .footerDiv > .pageNav > .pageNavArrow.clickable{
  cursor: pointer;
  opacity: 1;
}

.VITable .footerDiv > .pageNav > .pageNavArrow.clickable:hover{
  color: var(--color-highlight);
}
</style>
